import styled from 'styled-components';

interface IAvatarProps {
  src: string;
}

interface IAvatarOthersProps {
  src: string;
}

export const Container = styled.div``;

export const Welcome = styled.div`
  h1 {
    color: #e4e4e4;
  }
  p {
    color: #969696;
  }
`;

export const Values = styled.div`
  background: #242526;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;

  p {
    color: #606060;
    font-weight: 400;
    span {
      color: #cbccce;
      font-weight: 600;
    }
  }

  hr {
    background: #e0e0e0;
  }

  .dark-gray {
    color: #606060;
  }

  @media screen and (min-width: 768px) {
    .border-md-right {
      border-right: solid 1px #e0e0e0;
    }
  }
`;

export const Table = styled.div`
  background-color: #202020;
  border-radius: 20px;

  padding: 0 15px;
  margin-bottom: 10px;

  p {
    color: #cbccce;
    font-size: 18px;
  }

  .w-content {
    width: max-content;
  }

  .text-color {
    color: #6e6e6e;
  }

  .line {
    > div {
      width: 250px !important;
      overflow-wrap: break-word;
    }

    > div:nth-child(2),
    > div:nth-child(3) {
      width: 180px !important;
    }

    > div:nth-child(4) {
      width: 300px !important;
    }
  }

  img.avatar {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }

  div {
    span {
      color: #6e6e6e;
      font-size: 18px;
    }
  }

  .scroll {
    ::-webkit-scrollbar {
      border-radius: 4px !important;
      height: 8px !important;
    }
  }

  @media screen and (max-width: 1399px) {
    .m-width {
      min-width: 1000px;
      > div {
        min-width: 140px;
      }
    }
  }

  @media screen and (max-width: 575px) {
    div {
      span {
        color: #ddd;
      }
    }
  }
`;

export const Avatar = styled.div<IAvatarProps>`
  width: 141px;
  height: 141px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 4px rgba(228, 228, 228, 0.5);
  box-sizing: border-box;

  @media screen and (max-width: 1399px) {
    width: 106px;
    height: 106px;
  }
`;

export const Others = styled.div<IAvatarOthersProps>`
  width: 100px;
  height: 100px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 4px rgba(228, 228, 228, 0.5);
  box-sizing: border-box;

  @media screen and (max-width: 1399px) {
    width: 80px;
    height: 80px;
  }
`;
