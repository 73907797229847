/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';

import { useAuth } from '~/hooks/Auth';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';

import { Container, Welcome, Values, Table } from './styles';
import student from '~/assets/icons/student-icon.svg';
import affiliate from '~/assets/icons/affiliate-icon.svg';
import franchise from '~/assets/icons/franchise-purple-icon.svg';
import { useLanguage } from '~/hooks/Language';

interface IOrder {
  id: string;
  amount_paid: string;
  status: string;
  course?: {
    id: string;
    title: string;
  };
}

interface IReferrerResponse {
  id: string;
  student: {
    id: string;
    name: string;
    email: string;
    referral_code: string;
    avatar: {
      id: string;
      avatar_url: string;
    };
    affiliate: {
      id: string;
      active_affiliate: boolean;
      franchise_owner: boolean;
      product?: {
        id: string;
        name: string;
      };
    };
    orders: IOrder[];
  };
  created_at: string;
}

interface IReferrer {
  id: string;
  avatar: string;
  name: string;
  email: string;
  type: string;
  typeIcon: string;
  product: string;
  joined: string;
  referral_code: string;
}

const Referrals: React.FC = () => {
  const { user } = useAuth();
  const { language } = useLanguage();
  const [referrers, setReferrers] = useState<IReferrer[]>([]);
  const [total, setTotal] = useState('0.00');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    let totalData = 0;
    api
      .get<IReferrerResponse[]>(`referrers/${user.affiliate.id}`)
      .then((response) => {
        const data = response.data.map((referrer) => {
          const nameParts = referrer.student.name.split(' ');
          const joined = `Joined ${format(
            parseISO(referrer.created_at),
            'MMM dd, yyyy'
          )}`;
          let type = 'Student';
          let typeIcon = student;
          let product =
            referrer.student.orders[referrer.student.orders.length - 1] &&
            referrer.student.orders[referrer.student.orders.length - 1].course
              ?.title;

          totalData = referrer.student.orders.reduce(
            (acumulador: number, currentValue: IOrder) => {
              if (currentValue.status === 'succeeded') {
                return acumulador + parseFloat(currentValue.amount_paid);
              }
              return acumulador + 0;
            },
            totalData
          );

          if (referrer.student.affiliate.active_affiliate) {
            if (referrer.student.affiliate.franchise_owner) {
              type = 'Franchise Owner';
              typeIcon = franchise;
              product = referrer.student.affiliate.product?.name;
            } else {
              type = 'Affiliate';
              typeIcon = affiliate;
              product =
                referrer.student.orders[referrer.student.orders.length - 1]
                  .course?.title;
            }
          }
          return {
            id: referrer.id,
            avatar: referrer.student.avatar.avatar_url,
            name: `${nameParts[0]} ${nameParts[nameParts.length - 1][0]}.`,
            email: referrer.student.email,
            type,
            typeIcon,
            product: product || 'N/A',
            joined,
            referral_code: referrer.student.referral_code,
          };
        });
        setReferrers(data);
      })
      .finally(() => {
        setTotal(formatPrice(totalData));
        setLoading(false);
      });
  }, [user.affiliate.id]);

  return (
    <>
      <Container className="container px-0">
        <div className="w-100">
          <Welcome>
            <div className="row">
              <div className="col-12 my-4">
                <h1 className="h4 h2-sm text-center text-lg-left">
                  {language.my_referrals.h1}
                </h1>
              </div>
            </div>
          </Welcome>
          <div className="row justify-content-center">
            <Values className="col-11 col-xxl-10 mb-n5 d-md-flex justify-content-center mt-3 py-4">
              <div className="col-lg-4 text-center border-md-right">
                <p className="h4 mb-0 mb-sm-3">
                  <span>{referrers.length}</span>
                </p>
                <p className="mb-0 dark-gray">{language.my_referrals.p_1}</p>
              </div>
              {false && (
                <>
                  <hr className="w-25 mx-auto d-md-none" />
                  <div className="col-lg-4 text-center border-md-right">
                    <p className="h4 mb-0 mb-sm-3">
                      <span>$25,985</span>
                    </p>
                    <p className="mb-0 dark-gray">
                      {language.my_referrals.p_2}
                    </p>
                  </div>
                </>
              )}
              <hr className="w-25 mx-auto d-md-none" />
              <div className="col-lg-4 text-center">
                <p className="h4 mb-0 mb-sm-3">
                  <span>{total}</span>
                </p>
                <p className="mb-0 dark-gray">{language.my_referrals.p_3}</p>
              </div>
            </Values>
          </div>
          <div className="bg-gray pb-4">
            <div className="overflow-auto scroll">
              <Table className="pt-5">
                {referrers.map((referrer) => (
                  <Link
                    to={`${process.env.PUBLIC_URL}/my-referrals/team-view/${referrer.referral_code}`}
                    key={referrer.id}
                    className="w-100 d-flex align-items-center justify-content-around line mb-3"
                  >
                    <div className="px-0 text-center my-3 d-flex justify-content-center align-items-center">
                      <img
                        src={referrer.avatar}
                        className="avatar mr-4"
                        alt="avatar"
                      />
                      <span>{referrer.name}</span>
                    </div>
                    <div className="px-0 text-center">
                      <span>
                        <img src={referrer.typeIcon} alt="Icon" />{' '}
                        {referrer.type}
                      </span>
                    </div>
                    <div className="px-0 text-center">
                      <span>{referrer.product}</span>
                    </div>
                    <div className="px-0 text-center">
                      <span>{referrer.email}</span>
                    </div>
                    <div className="px-0 text-center d-none">
                      <span>Bogota, Colombia</span>
                    </div>
                    <div className="px-0 text-center">
                      <span>{referrer.joined}</span>
                    </div>
                  </Link>
                ))}
              </Table>
            </div>
          </div>
        </div>
      </Container>
      {loading && (
        <>
          <div className="loading-box">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Referrals;
