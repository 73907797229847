import styled from 'styled-components';

export const Container = styled.div`
  background: #242526;
  border-radius: 20px;

  .TitleBox {
    color: #f5f6f8;
    display: table;
    margin: 0 auto;
  }

  .ValueBox {
    display: table;
    margin: 0 auto;
    color: white;
    font-weight: bolder;
    font-size: 30px;
    margin-bottom: 5%;
  }

  .TimeBox {
    color: #828282;
  }

  .Active {
    color: white;
    font-weight: 600;
  }

  .MonthBox {
    margin-top: 1%;
    margin-bottom: 5%;
  }

  .ItemMonthBox {
    background-color: #20202000 !important;
    border: none;
    padding: 2px;
    background-image: linear-gradient(180deg, #24252600, #24252600),
      linear-gradient(269.95deg, #e323ff00 0.02%, #79d1f800 97.45%) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-image-slice: 1 !important;
    color: #fff;
  }

  .ItemMonthBox:hover,
  .ItemMonthBox.Active {
    background-color: #202020 !important;
    border: 1px solid #828282 !important;
    padding: 2px;
    color: #fff;
  }

  .WithDrawBox {
    background-color: #18191a;
    box-shadow: -2px 5px 16px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
  }

  .WithDrawBox .TitleWithDrawBox {
    color: #828282;
  }

  .WithDrawBox .ValueWithDrawBox {
    color: white;
    font-weight: bolder;
    font-size: 20px;
  }

  .border-gold {
    background-color: #202020 !important;
    border: none;
    padding: 1px;
    background-image: linear-gradient(180deg, #242526, #242526),
      linear-gradient(
        143.88deg,
        #825511 -9.17%,
        #f3bf5c 108.32%,
        #f3bf5c 108.32%
      ) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-image-slice: 1 !important;
    color: #fff;
  }

  .ButtonWithDrawBox {
    border: solid 1px #37c694;
    background-color: #18191a;
    color: #f7f7f7;
    padding: 0.5rem 1.5rem;

    :hover {
      filter: brightness(1.3);
    }
  }

  .WithDrawBox .ButtonBox {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .WithDrawBox .ButtonBox .ButtonWithDrawBox {
  }

  .ItemTimeBox {
    transition-duration: 0.3s;
    color: #828282;
  }

  .ItemTimeBox:hover,
  .ItemTimeBox.Active {
    color: #f5f6f8;
  }
`;
