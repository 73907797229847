import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { lighten } from 'polished';

export const Container = styled.div``;

export const Welcome = styled.div`
  h1 {
    color: #e4e4e4;
  }
  p {
    color: #858585;
  }

  .search {
    padding: 1px;
    border-radius: 100px;
    border: 1px solid #4a4b4b;
    background-color: #202020;

    input {
      margin: 5px 0;
      padding: 0 10px;
      border: none;
      border-radius: 100px;
      background-color: #202020;
    }
  }
  .add-training {
    border: 1px solid #4a4b4b;
    background-color: #202020;
    color: #e4e4e4;
    text-decoration: none !important;
    font-size: 12px;
    font-weight: 600;
    :hover {
      border-color: ${lighten(0.1, '#4a4b4b')};
      color: ${lighten(0.1, '#e4e4e4')};
      transition-duration: 0.5s;
      img {
        filter: brightness(1.3);
      }
    }
  }
`;

export const Search = styled.div`
  padding: 1px;
  border-radius: 100px;
  border: 1px solid #4a4b4b;
  background-color: #202020;

  input {
    margin: 5px 0;
    padding: 0 10px;
    border: none;
    border-radius: 100px;
    background-color: #202020;
  }
`;

export const Card = styled(Link)`
  border-radius: 20px;
  overflow: hidden;

  img {
    border-radius: 20px;
  }

  .top_0 {
    top: 0 !important;
  }

  .training-img {
    height: 350px;
  }

  .mkt-img {
    height: 250px;
  }

  > div {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: -167px;
    border-radius: 20px;
    height: 350px;

    /* p {
      width: 55%;
    } */

    .edit {
      background-color: transparent;
      border: none;
      color: rgba(203, 204, 206, 1);
      text-decoration: none;
      img {
        border-radius: 0px;
      }
      :hover {
        color: ${lighten(0.1, 'rgba(203, 204, 206, 1)')};
        transition-duration: 0.5s;
        img {
          filter: brightness(1.3);
        }
      }
    }

    a.btn-more {
      width: 40%;
      background: rgba(111, 110, 110, 1);
      border: none;
      color: rgba(203, 204, 206, 1);
      text-align: center;

      :hover {
        text-decoration: none;
        color: ${lighten(0.1, 'rgba(203, 204, 206, 1)')};
        background-color: ${lighten(0.1, 'rgba(111, 110, 110, 1)')};
        transition-duration: 0.5s;
      }
    }
  }

  @media screen and (max-width: 575px) {
    > div {
      height: 231px;
      bottom: -107px;
    }

    .training-img {
      height: 231px;
    }
    .btn-more {
      padding: 10px 40px;
    }
  }
`;
