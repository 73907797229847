import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { IDataTableColumn as IColumn } from 'react-data-table-component';

import { format, parseISO } from 'date-fns';
import { formatPrice } from '~/utils/format';

import { Container, Welcome, NameStudentTable } from './styles';
import TableData from '~/components/Table';

import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';

import logo from '~/assets/logotipos/logo-profile.png';

interface ITableData {
  id: string;
  transactionDate: string;
  orderID: string;
  avatar: string;
  name: string;
  level: string;
  referrer: string;
  product: string;
  amountPaid: string;
  commission: string;
  refund: boolean;
}

interface IStudent {
  avatar: {
    avatar_url: string;
  };
  name: string;
}
interface ICourse {
  title: string;
}

interface IProduct {
  name: string;
}
interface IOrder {
  affiliate_id: string;
  amount_paid: number;
  created_at: string;
  id: string;
  product_id: string;
  student: IStudent;
  course: ICourse;
  product: IProduct;
}

interface ICommission {
  id: string;
  amount_paid: number;
  earning: number;
  gain_origin: string;
  created_at: string;
  order?: IOrder;
}

const Students: React.FC = () => {
  const { language } = useLanguage();
  const [students, setStudents] = useState<ITableData[]>([]);
  const [loading, setLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), 11, 31, 23, 59, 59, 99)
  );

  useEffect(() => {
    if (firstLoading) {
      setLoading(true);
      setFirstLoading(false);
    }

    api
      .get(`commissions`, {
        params: {
          search,
          startDate,
          endDate,
        },
      })
      .then((response) => {
        const data = response.data.map((commission: ICommission) => ({
          transactionDate: format(
            parseISO(commission.created_at),
            'MM-dd-yyyy'
          ),

          orderID: commission.order
            ? commission.order.id
            : `${
                commission.gain_origin.match('Crowdfunding')
                  ? 'Crowdfunding Distribution'
                  : commission.gain_origin
              }`,
          avatar: commission.order
            ? commission.order.student.avatar.avatar_url
            : logo,
          name: commission.order ? commission.order.student.name : 'Wiser',
          product:
            commission.order && commission.order.course
              ? `Course: ${commission.order.course.title}`
              : `${
                  commission.gain_origin.match('Crowdfunding')
                    ? 'Crowdfunding Distribution'
                    : commission.gain_origin
                }${
                  commission.order ? `: ${commission.order.product.name}` : ''
                }`,
          amountPaid: formatPrice(commission.amount_paid),
          commission: formatPrice(commission.earning),
          refund: commission.earning < 0,
        }));
        setStudents(data);
        setLoading(false);
      });
  }, [endDate, firstLoading, search, startDate]);

  const columns = useMemo<IColumn[]>(
    () => [
      {
        name: language.commission_history.th_1,
        selector: 'transactionDate',
        sortable: true,
      },
      {
        name: language.commission_history.th_2,
        selector: 'orderID',
        sortable: true,
      },
      {
        name: language.commission_history.th_3,
        selector: 'name',
        sortable: true,
        cell: (row: any) => (
          <NameStudentTable
            data-tag="allowRowEvents"
            className="d-flex align-items-center"
          >
            <img src={row.avatar} alt={row.name} className="mr-2" />
            <p className="mb-0">{row.name}</p>
          </NameStudentTable>
        ),
      },
      {
        name: language.commission_history.th_4,
        selector: 'product',
        sortable: true,
      },
      {
        name: language.commission_history.th_5,
        selector: 'amountPaid',
        sortable: true,
        cell: (row: any) => <p className="mb-0">{row.amountPaid}</p>,
      },
      {
        name: language.commission_history.th_6,
        selector: 'commission',
        sortable: true,
        cell: (row: any) => (
          <p className={`mb-0 ${row.refund ? 'text-red' : 'text-green'}`}>
            {row.commission}
          </p>
        ),
      },
    ],
    [
      language.commission_history.th_1,
      language.commission_history.th_2,
      language.commission_history.th_3,
      language.commission_history.th_4,
      language.commission_history.th_5,
      language.commission_history.th_6,
    ]
  );

  const handleSearch = useCallback(async (value) => {
    setSearch(value);
  }, []);

  const handleChangeStartDate = useCallback((value) => {
    setStartDate(value);
  }, []);

  const handleChangeEndDate = useCallback((value) => {
    setEndDate(value);
  }, []);

  return (
    <Container>
      <div className="container">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome className="mb-3">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <h1 className="h4 h2-sm my-4 text-center text-lg-left">
                      {language.commission_history.h1}
                    </h1>
                  </div>
                </div>
              </div>
            </Welcome>
            <TableData
              title={language.commission_history.h1}
              data={students}
              columns={columns}
              pagination
              searchable
              onSearch={handleSearch}
              exportable
              date
              initialDate={startDate}
              finalDate={endDate}
              onChangeStartDate={handleChangeStartDate}
              onChangeLastDate={handleChangeEndDate}
            />
          </div>
        </div>
      </div>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Students;
