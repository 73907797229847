import React, { useCallback, useState } from 'react';

import { VideoPlayer } from './styles';

interface IPlayerProps {
  src: string;
  id?: string;
  className?: string;
  onPlay?(): void;
  onPause?(): void;
  callChangeTimeBySeconds?: number;
  onChangeTime?(time: number): void;
  onComplete?(): void;
}

const Player: React.FC<IPlayerProps> = ({
  src,
  id,
  className,
  onPlay,
  onPause,
  callChangeTimeBySeconds,
  onChangeTime,
  onComplete,
}) => {
  const [time, setTime] = useState(0);

  const handlePlayVideo = useCallback(() => {
    if (onPlay) {
      onPlay();
    }
  }, [onPlay]);

  const handlePauseVideo = useCallback(() => {
    if (onPause) {
      onPause();
    }
  }, [onPause]);

  const handleChangeTime = useCallback(
    (e) => {
      const videoTime = parseInt(e.currentTime, 10);
      const callTime = callChangeTimeBySeconds || 10;
      if (time !== videoTime && videoTime % callTime === 0) {
        setTime(videoTime);
        if (onChangeTime) {
          onChangeTime(videoTime);
        }
      }
    },
    [callChangeTimeBySeconds, onChangeTime, time]
  );

  const handleCompleteVideo = useCallback(() => {
    if (onComplete) {
      onComplete();
    }
  }, [onComplete]);

  return (
    <VideoPlayer
      playerId="m4VQyNqP"
      playerScript="https://cdn.jwplayer.com/libraries/Z7fF2l1i.js"
      file={src}
      onPlay={handlePlayVideo}
      onPause={handlePauseVideo}
      onTime={handleChangeTime}
      onComplete={handleCompleteVideo}
      id={id}
      className={className}
    />
  );
};

export default Player;
