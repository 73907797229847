import styled from 'styled-components';
import ReactJWPlayer from 'react-jw-player';

export const VideoPlayer = styled(ReactJWPlayer)`
  width: 100%;
  height: 42vh;

  > div {
    width: 100% !important;
    height: 100% !important;
    border-radius: 20px;
  }
`;
