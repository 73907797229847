import React, { useCallback, useState } from 'react';
import { useLanguage } from '~/hooks/Language';

import { Container } from './styles';
import notes from '~/assets/icons/notes.svg';

const Notes: React.FC = () => {
  const { language } = useLanguage();
  const [textareaSelected, setTextareaSelected] = useState(false);
  const [textareaContent, setTextareaContent] = useState(0);

  const handleClick = useCallback(() => {
    setTextareaSelected(true);
  }, []);

  const handleFocus = useCallback(() => {
    setTextareaSelected(true);
  }, []);

  const handleBlur = useCallback(() => {
    setTextareaSelected(false);
  }, []);

  const handleChange = useCallback((e) => {
    setTextareaContent(e.target.value.length);
  }, []);

  return (
    <Container
      className="px-3 py-4 h-100"
      textareaSelected={textareaSelected || textareaContent !== 0}
    >
      {!textareaSelected && textareaContent === 0 && (
        <button
          type="button"
          className="border-0 bg-transparent d-flex align-items-center"
          onClick={handleClick}
        >
          <img src={notes} alt="File" className="mr-1" />
          <p className="mb-0 ml-1">{language.training.placeholder}</p>
        </button>
      )}
      <textarea
        id="notes"
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        className="form-control"
      />
    </Container>
  );
};

export default Notes;
