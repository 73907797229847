import styled, { css } from 'styled-components';

interface ContainerProps {
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  input.pincode-input-text {
    opacity: 0.8;
    border: 1.5px solid #3d3e3f !important;
    box-sizing: border-box;
    border-radius: 15px;
    width: 70px !important;
    height: 70px !important;
    flex: 1;
    background: transparent;
    border: 0;
    color: #efefef;
    transition-duration: 0.2s;
    margin: 0 5px !important;

    ::placeholder {
      color: #efefef;
    }

    :focus {
      border: 1.5px solid #efefef !important;
    }
  }

  svg {
    margin-right: 16px;
    color: #666360;
    transition-duration: 0.2s;
  }

  ${(props) =>
    props.isErrored &&
    css`
      input.pincode-input-text {
        border-color: #c53030 !important;
      }
    `}
`;
