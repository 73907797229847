import styled from 'styled-components';

export const Container = styled.div`
  .rdt_TableHeadRow,
  .rdt_TableRow {
    div {
      div,
      p {
        white-space: unset;
        width: 100%;
        text-align: center;
      }
    }

    > div:first-child {
      max-width: 150px;
    }
  }
`;

export const Welcome = styled.div`
  h1 {
    color: #e4e4e4;
  }
  p {
    color: #969696;
  }
`;

export const NameStudentTable = styled.div`
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
`;
