import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';

import api from '~/services/api';
import Categories from '~/components/Categories';
import { Container, Welcome, Search, Card } from './styles';
import { useLanguage } from '~/hooks/Language';

import search from '~/assets/icons/search-icon.svg';

interface ITrainingResponse {
  id: string;
  title: string;
  description: string;
  thumbnail: {
    thumbnail_url: string;
  };
  slug: string;
}

interface ITrainingCategoryResponse {
  id: string;
  training: ITrainingResponse;
}

interface ITraining {
  id: string;
  title: string;
  description: string;
  thumbnail: string;
  slug: string;
}

interface IParamsProps {
  slug?: string;
}

const TrainingList: React.FC = () => {
  const { language } = useLanguage();
  const params = useParams<IParamsProps>();
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');
  const [category, setCategory] = useState(
    `${language.affiliates_categories.nav}`
  );
  const [trainings, setTrainings] = useState<ITraining[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    if (params.slug) {
      setSearchValue('');
      api
        .get(`categories/students/${params.slug}`, {
          params: {
            type: 'trainings',
          },
        })
        .then(async (response) => {
          const data = response.data.trainingCategory.map(
            (trainingCategory: ITrainingCategoryResponse) => ({
              id: trainingCategory.training.id,
              title: trainingCategory.training.title,
              description: trainingCategory.training.description,
              thumbnail: trainingCategory.training.thumbnail.thumbnail_url,
              slug: trainingCategory.training.slug,
            })
          );
          setTrainings(data);
          setCategory(response.data.name);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      api
        .get('trainings/students/')
        .then(async (response) => {
          const data = response.data.map((training: ITrainingResponse) => ({
            id: training.id,
            title: training.title,
            description: training.description,
            thumbnail: training.thumbnail.thumbnail_url,
            slug: training.slug,
          }));
          setTrainings(data);
          setCategory(`${language.affiliates_categories.nav}`);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [params.slug]);

  const handleSearch = useCallback(
    async (e) => {
      history.push(`${process.env.PUBLIC_URL}/trainings`);
      setSearchValue(e.target.value);
      const response = await api.get('trainings/students', {
        params: {
          title: e.target.value,
        },
      });

      if (response.data) {
        const data = response.data.map((training: ITrainingResponse) => ({
          id: training.id,
          title: training.title,
          description: training.description,
          thumbnail: training.thumbnail.thumbnail_url,
          slug: training.slug,
        }));
        setTrainings(data);
      }
    },
    [history]
  );

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-8">
                    <h1 className="h2 h1-lg mb-0">
                      {language.affiliates_training.h1}
                    </h1>
                    <p className="mt-4 font-weight-300">
                      {language.affiliates_training.p_1}
                    </p>
                  </div>
                  <div className="d-none d-lg-block col-lg-4 mt-4 mt-sm-0 text-center text-sm-left">
                    <Search className="d-flex">
                      <input
                        className="w-100"
                        placeholder={`${language.affiliates_training.placeholder_1}`}
                        onChange={handleSearch}
                        value={searchValue}
                      />
                      <img src={search} alt="Search" className="mx-2" />
                    </Search>
                  </div>
                </div>
              </div>
            </Welcome>
          </div>
          <div className="col-xl-9 mt-5">
            {trainings[0] && (
              <Card
                to={`${process.env.PUBLIC_URL}/trainings/${trainings[0].slug}`}
                className="p-relative text-white"
              >
                <>
                  <img
                    src={trainings[0].thumbnail}
                    alt="Marketing to
                      wooComerce"
                    className="w-100 training-img"
                  />
                  <div className="p-absolute w-100 px-2">
                    <p className="mb-0 pl-4 pr-0 pr-sm-4 pt-4 h4 h2-sm">
                      {trainings[0].title}
                    </p>
                  </div>
                </>
              </Card>
            )}
          </div>
          <div className="mt-5 col-md-4 col-xl-3">
            <Categories type="trainings" />
          </div>
          <div className="col-md-8 col-xl-12 mt-0 mt-md-5 mt-lg-4">
            <div className="row justify-content-lg-between justify-content-xl-start">
              <div className="col-12 mt-xl-5">
                <h2 className="h5">
                  {searchValue ? `Filter: ${searchValue}` : category}
                </h2>
              </div>

              {trainings.length > 0 ? (
                <>
                  {trainings.map((training, index) => (
                    <Fragment key={training.id}>
                      {index > 0 && (
                        <div className="col-sm-6 col-xl-3 my-3">
                          <Card
                            to={`${process.env.PUBLIC_URL}/trainings/${training.slug}`}
                            className="p-relative d-block  text-white"
                          >
                            <img
                              src={training.thumbnail}
                              alt={training.title}
                              className="w-100 mkt-img"
                            />
                            <div className="p-absolute w-100 h-100 top_0">
                              <div className="w-100 h-100 row mx-0">
                                <div className="col-12">
                                  <p className="mb-0 px-3 pt-4 h4">
                                    {training.title}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </div>
                      )}
                    </Fragment>
                  ))}
                </>
              ) : (
                <div className="col-12">
                  <p className="h5 text-center">
                    {language.affiliates_training.p_2}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default TrainingList;
