import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useField } from '@unform/core';
import PinInput from 'react-pin-input';

import { Container } from './styles';

interface InputCodeProps {
  name: string;
  onChange?(event: any): void;
  value?: string;
  id?: string;
  className?: string;
  error?: string;
}

const InputCode: React.FC<InputCodeProps> = ({
  name,
  onChange,
  value,
  id,
  className,
  error,
}) => {
  const codeRef = useRef<any>(null);
  const [newValue, setNewValue] = useState(value || '');

  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: codeRef.current,
      path: 'props.value',
    });
  }, [fieldName, registerField]);

  const handleChange = useCallback(
    (e) => {
      if (onChange) {
        const data = {
          target: {
            name,
            value: e,
          },
        };
        onChange(data);
      }
      setNewValue(e);
    },
    [name, onChange]
  );

  return (
    <>
      <Container id={id} className={className} isErrored={!!error}>
        <PinInput
          ref={codeRef}
          initialValue={newValue}
          length={6}
          onChange={handleChange}
          onComplete={handleChange}
          type="custom"
          autoSelect
        />
      </Container>
      {error && <span className="small text-danger error">{error}</span>}
    </>
  );
};

export default InputCode;
