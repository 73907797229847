import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useLayoutEffect,
} from 'react';
import Carousel from 'react-elastic-carousel';

import api from '~/services/api';
import { useAuth } from '~/hooks/Auth';

import { Container, Img, Slot, CardProfile, ImgCard } from './styles';
import fireBall from '~/assets/icons/franchise-blue-icon.svg';
import more from '~/assets/icons/more-profiles-icon.svg';
import arrow from '~/assets/icons/arrow-right.svg';

interface ICrowdfundingAffiliatesResponse {
  id: string;
  affiliate: {
    student: {
      name: string;
      referral_code: string;
      address: string;
      avatar: {
        avatar_url: string;
      };
    };
    product: {
      id: string;
      name: string;
    };
  };
}

interface ICrowdfundingAffiliates {
  id: string;
  name: string;
  referral_code: string;
  address: string;
  avatar: string;
  franchise: string;
}

interface IPyramidProps {
  linesUnlock: number;
}

interface IUser {
  id: string;
  avatar: string;
  name: string;
  referrer_code: string;
  product: string;
}

const Pyramid: React.FC<IPyramidProps> = ({ linesUnlock }) => {
  // const carouselRef = useRef<ICarousel>(null);
  const { user } = useAuth();
  const [crowdfundingAffiliates, setCrowdfundingAffiliates] = useState<
    ICrowdfundingAffiliates[]
  >([]);
  const [crowdfundingDatas, setCrowdfundingDatas] = useState<
    ICrowdfundingAffiliates[][]
  >([]);
  const [userSelectedOld, setUserSelectedOld] = useState<IUser>({} as IUser);
  const [userSelected, setUserSelected] = useState<IUser>({} as IUser);

  const [width, setWidth] = useState(0);
  const [show9, setShow9] = useState(5);
  const [show27, setShow27] = useState(7);
  const [show81, setShow81] = useState(9);
  const [show243, setShow243] = useState(11);
  const [show729, setShow729] = useState(13);
  const [show2187, setShow2187] = useState(15);
  const [show6561, setShow6561] = useState(17);
  const [cardWidth, setCardWidth] = useState(220);
  const [cardHeight, setCardHeight] = useState(580);

  const updateSize = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [updateSize]);

  useEffect(() => {
    if (width >= 2300) {
      setCardWidth(583);
      setCardHeight(580);
      setShow9(5);
      setShow27(7);
      setShow81(9);
      setShow243(11);
      setShow729(13);
      setShow2187(14);
      setShow6561(14);
    } else if (width >= 1800) {
      setCardWidth(373);
      setCardHeight(580);
      setShow9(5);
      setShow27(7);
      setShow81(9);
      setShow243(11);
      setShow729(13);
      setShow2187(14);
      setShow6561(14);
    } else if (width >= 1600) {
      setCardWidth(263);
      setCardHeight(580);
      setShow9(5);
      setShow27(7);
      setShow81(9);
      setShow243(11);
      setShow729(13);
      setShow2187(14);
      setShow6561(14);
    } else if (width >= 1500) {
      setCardWidth(200);
      setCardHeight(580);
      setShow9(5);
      setShow27(7);
      setShow81(9);
      setShow243(11);
      setShow729(13);
      setShow2187(14);
      setShow6561(14);
    } else if (width >= 1400) {
      setCardWidth(140);
      setCardHeight(590);
      setShow9(5);
      setShow27(7);
      setShow81(9);
      setShow243(11);
      setShow729(12);
      setShow2187(12);
      setShow6561(12);
    } else if (width >= 1300) {
      setCardWidth(140);
      setCardHeight(610);
      setShow9(5);
      setShow27(7);
      setShow81(9);
      setShow243(11);
      setShow729(12);
      setShow2187(12);
      setShow6561(12);
    } else if (width >= 1200) {
      setCardWidth(140);
      setCardHeight(620);
      setShow9(5);
      setShow27(7);
      setShow81(9);
      setShow243(10);
      setShow729(10);
      setShow2187(10);
      setShow6561(10);
    } else if (width >= 1100) {
      setCardWidth(120);
      setCardHeight(700);
      setShow9(5);
      setShow27(7);
      setShow81(9);
      setShow243(10);
      setShow729(10);
      setShow2187(10);
      setShow6561(10);
    } else if (width >= 992) {
      setCardWidth(120);
      setCardHeight(720);
      setShow9(5);
      setShow27(7);
      setShow81(9);
      setShow243(9);
      setShow729(9);
      setShow2187(9);
      setShow6561(9);
    } else if (width >= 768) {
      setCardWidth(70);
      setCardHeight(660);
      setShow9(5);
      setShow27(7);
      setShow81(7);
      setShow243(7);
      setShow729(7);
      setShow2187(7);
      setShow6561(7);
    } else if (width >= 575) {
      setCardWidth(60);
      setCardHeight(1000);
      setShow9(5);
      setShow27(5);
      setShow81(5);
      setShow243(5);
      setShow729(5);
      setShow2187(5);
      setShow6561(5);
    } else if (width >= 450) {
      setCardWidth(60);
      setCardHeight(1100);
      setShow9(3);
      setShow27(3);
      setShow81(3);
      setShow243(3);
      setShow729(3);
      setShow2187(3);
      setShow6561(3);
    } else if (width >= 400) {
      setCardWidth(60);
      setCardHeight(1150);
      setShow9(3);
      setShow27(3);
      setShow81(3);
      setShow243(3);
      setShow729(3);
      setShow2187(3);
      setShow6561(3);
    } else if (width >= 350) {
      setCardWidth(60);
      setCardHeight(1280);
      setShow9(3);
      setShow27(3);
      setShow81(3);
      setShow243(3);
      setShow729(3);
      setShow2187(3);
      setShow6561(3);
    } else {
      setCardWidth(70);
      setCardHeight(1350);
      setShow9(3);
      setShow27(3);
      setShow81(3);
      setShow243(3);
      setShow729(3);
      setShow2187(3);
      setShow6561(3);
    }
  }, [width]);

  useEffect(() => {
    api
      .get<ICrowdfundingAffiliatesResponse[]>(
        `crowdfunding-affiliates/${user.affiliate.id}`
      )
      .then((response) => {
        const data: ICrowdfundingAffiliates[] = response.data.map(
          (crowdfundingAffiliate) => ({
            id: crowdfundingAffiliate.id,
            name: crowdfundingAffiliate.affiliate.student.name,
            address: crowdfundingAffiliate.affiliate.student.address,
            referral_code:
              crowdfundingAffiliate.affiliate.student.referral_code,
            avatar: crowdfundingAffiliate.affiliate.student.avatar.avatar_url,
            franchise: crowdfundingAffiliate.affiliate.product
              ? crowdfundingAffiliate.affiliate.product.name
              : '',
          })
        );
        setCrowdfundingAffiliates(data);
      });
  }, [user, user.affiliate.id]);

  useEffect(() => {
    const newDataList: ICrowdfundingAffiliates[][] = [];

    const crowdfundingList = crowdfundingAffiliates.slice();
    let prev = 0;
    for (let i = 1; i <= 8; i += 1) {
      // console.log(3 ** i);
      // eslint-disable-next-line no-loop-func
      const minIndex = prev;
      const maxIndex = 3 ** i + prev;
      const data = crowdfundingList.filter(
        (_, index) => minIndex <= index && index < maxIndex
      );
      newDataList.push(data);

      prev = maxIndex;
    }

    setCrowdfundingDatas(newDataList);
  }, [crowdfundingAffiliates]);

  const breakPoints = useMemo(() => [{ width: 0 }], []);

  const handleMouseEnterMyProfile = useCallback((e) => {
    const element = e.target.closest('.border-gray');
    element.classList.add('active');
  }, []);

  const handleMouseLeaveMyProfile = useCallback((e) => {
    const element = e.target.closest('.border-gray');
    element.classList.remove('active');
  }, []);

  const handleMouseEnter = useCallback(
    (e, data: ICrowdfundingAffiliates) => {
      const element = e.target.closest('.border-gray');
      const elementCardProfile = document.getElementById('cardProfile');
      if (elementCardProfile && element) {
        const dataUser = {
          id: data.id,
          avatar: data.avatar,
          name: data.name,
          product: data.franchise,
          referrer_code: data.referral_code,
        };

        setUserSelectedOld(dataUser);
        setUserSelected(dataUser);

        const { top, left } = e.target.getBoundingClientRect();
        const y = top + window.pageYOffset;
        const x = left + window.pageXOffset;
        elementCardProfile.style.top = `${y - cardHeight}px`;
        elementCardProfile.style.left = `${x - cardWidth}px`;
        elementCardProfile.style.opacity = '1';
        elementCardProfile.style.visibility = 'visible';
      }
    },
    [cardHeight, cardWidth]
  );

  const handleMouseEnterCard = useCallback(
    (e) => {
      e.target.style.opacity = '1';
      e.target.style.visibility = 'visible';
      setUserSelected(userSelectedOld);
    },
    [userSelectedOld]
  );

  const handleMouseLeave = useCallback(() => {
    const elementCardProfile = document.getElementById('cardProfile');
    if (elementCardProfile) {
      elementCardProfile.style.opacity = '0';
      elementCardProfile.style.visibility = 'hidden';
      setUserSelected({} as IUser);
    }
  }, []);

  const renderSlots = useCallback(
    (quantity, line) => {
      const slots = [];
      for (let i = 0; i < quantity; i += 1) {
        if (line > linesUnlock) {
          slots.push(<Slot className="border-gray" />);
        } else {
          slots.push(<Slot className="border-gray p-relative" />);
        }
      }
      return slots;
    },
    [linesUnlock]
  );

  return (
    <>
      <CardProfile
        id="cardProfile"
        onMouseEnter={handleMouseEnterCard}
        onMouseLeave={handleMouseLeave}
        className="p-absolute text-center p-4 d-flex flex-column justify-content-center"
      >
        <ImgCard
          src={userSelected.avatar}
          size={95}
          borderWidth={4}
          borderRadius={30}
          className="mx-auto mb-3"
        />
        <h4 className="h5 font-weight-400">{userSelected.name}</h4>
        <p className="h6 font-weight-300 mb-0">{userSelected.referrer_code}</p>
        <hr />
        <div className="d-flex align-items-center justify-content-center">
          {/* <img src={fireBall} alt="Franchise" className="fireBall" /> */}
          <p className="h5 font-weight-400 ml-1 mb-0">{userSelected.product}</p>
        </div>
        {/* <p className="text-golden">Japan, English</p> */}
      </CardProfile>
      <Container className="pb-3">
        <div className="d-flex pl-lg-2 mb-4 row-profiles align-items-center justify-content-center justify-content-lg-start">
          <div className="border-gray first-profile p-relative ">
            <Img
              src={user.avatar.avatar_url}
              size={95}
              borderWidth={4}
              borderRadius={30}
              hasShadow
            />
            <CardProfile
              myProfile
              onMouseEnter={handleMouseEnterMyProfile}
              onMouseLeave={handleMouseLeaveMyProfile}
              className="p-absolute text-center p-3 position"
            >
              <img src={user.avatar.avatar_url} alt={user.name} />
              <h4>{user.name}</h4>
              <p>{user.referral_code}</p>
              {false && (
                <>
                  <hr />
                  <div className="d-flex align-items-center justify-content-center mb-2">
                    <img
                      src={fireBall}
                      alt="Franchise"
                      className="fireBall border-0"
                    />
                    <p className="ml-1 mb-0">IM Academy</p>
                  </div>
                  <p className="text-golden">Japan, English</p>
                </>
              )}
            </CardProfile>
          </div>
        </div>
        {crowdfundingDatas.map((crowdfundingData, index) => (
          <div key={index} className="d-flex align-items-center">
            {index === 0 && (
              <>
                <div className="square-3">
                  <Carousel
                    isRTL={false}
                    pagination={false}
                    showArrows={false}
                    itemsToShow={3}
                    // enableAutoPlay
                    // autoPlaySpeed={1500}
                    breakPoints={breakPoints}
                  >
                    {crowdfundingData.map((crowdfunding) => (
                      <Slot
                        key={crowdfunding.id}
                        className="border-gray p-relative"
                      >
                        <Img
                          src={crowdfunding.avatar}
                          size={60}
                          borderWidth={0}
                          borderRadius={20}
                          onMouseEnter={(e) =>
                            handleMouseEnter(e, crowdfunding)
                          }
                          onMouseLeave={handleMouseLeave}
                        />
                      </Slot>
                    ))}
                    {crowdfundingData.length < 3 &&
                      renderSlots(3 - crowdfundingData.length, index + 1)}
                  </Carousel>
                </div>
                <p className="h5 h4-sm h4-xl number-color mb-0 ml-2">3</p>
              </>
            )}
            {index === 1 && (
              <>
                <div className="square-9">
                  <Carousel
                    isRTL={false}
                    pagination={false}
                    showArrows={false}
                    itemsToShow={show9}
                    // enableAutoPlay
                    // autoPlaySpeed={1500}
                    breakPoints={breakPoints}
                  >
                    {crowdfundingData.map((crowdfunding) => (
                      <Slot
                        key={crowdfunding.id}
                        className="border-gray p-relative"
                      >
                        <Img
                          src={crowdfunding.avatar}
                          size={60}
                          borderWidth={0}
                          borderRadius={20}
                          onMouseEnter={(e) =>
                            handleMouseEnter(e, crowdfunding)
                          }
                          onMouseLeave={handleMouseLeave}
                        />
                      </Slot>
                    ))}
                    {crowdfundingData.length < 9 &&
                      renderSlots(5 - crowdfundingData.length, index + 1)}
                  </Carousel>
                </div>
                <img src={more} alt="More" className="w-4 d-none d-md-block" />
                <img src={arrow} alt="More" className="d-md-none" />
                <p className="h5 h4-sm h4-xl number-color mb-0 ml-2">9</p>
              </>
            )}
            {index === 2 && (
              <>
                <div className="square-27">
                  <Carousel
                    isRTL={false}
                    pagination={false}
                    showArrows={false}
                    itemsToShow={show27}
                    // enableAutoPlay
                    // autoPlaySpeed={1500}
                    breakPoints={breakPoints}
                  >
                    {crowdfundingData.map((crowdfunding) => (
                      <Slot
                        key={crowdfunding.id}
                        className="border-gray p-relative"
                      >
                        <Img
                          src={crowdfunding.avatar}
                          size={60}
                          borderWidth={0}
                          borderRadius={20}
                          onMouseEnter={(e) =>
                            handleMouseEnter(e, crowdfunding)
                          }
                          onMouseLeave={handleMouseLeave}
                        />
                      </Slot>
                    ))}
                    {crowdfundingData.length < 27 &&
                      renderSlots(7 - crowdfundingData.length, index + 1)}
                  </Carousel>
                </div>
                <img src={more} alt="More" className="w-4 d-none d-md-block" />
                <img src={arrow} alt="More" className="d-md-none" />
                <p className="h5 h4-sm h4-xl number-color mb-0 ml-2">27</p>
              </>
            )}
            {index === 3 && (
              <>
                <div className="square-81">
                  <Carousel
                    isRTL={false}
                    pagination={false}
                    showArrows={false}
                    itemsToShow={show81}
                    // enableAutoPlay
                    // autoPlaySpeed={1500}
                    breakPoints={breakPoints}
                  >
                    {crowdfundingData.map((crowdfunding) => (
                      <Slot
                        key={crowdfunding.id}
                        className="border-gray p-relative"
                      >
                        <Img
                          src={crowdfunding.avatar}
                          size={60}
                          borderWidth={0}
                          borderRadius={20}
                          onMouseEnter={(e) =>
                            handleMouseEnter(e, crowdfunding)
                          }
                          onMouseLeave={handleMouseLeave}
                        />
                      </Slot>
                    ))}
                    {crowdfundingData.length < 81 &&
                      renderSlots(9 - crowdfundingData.length, index + 1)}
                  </Carousel>
                </div>
                <img src={more} alt="More" className="w-4 d-none d-md-block" />
                <img src={arrow} alt="More" className="d-md-none" />
                <p className="h5 h4-sm h4-xl number-color mb-0 ml-2">81</p>
              </>
            )}
            {index === 4 && (
              <>
                <div className="square-243">
                  <Carousel
                    isRTL={false}
                    pagination={false}
                    showArrows={false}
                    itemsToShow={show243}
                    // enableAutoPlay
                    // autoPlaySpeed={1500}
                    breakPoints={breakPoints}
                  >
                    {crowdfundingData.map((crowdfunding) => (
                      <Slot
                        key={crowdfunding.id}
                        className="border-gray p-relative"
                      >
                        <Img
                          src={crowdfunding.avatar}
                          size={60}
                          borderWidth={0}
                          borderRadius={20}
                          onMouseEnter={(e) =>
                            handleMouseEnter(e, crowdfunding)
                          }
                          onMouseLeave={handleMouseLeave}
                        />
                      </Slot>
                    ))}
                    {crowdfundingData.length < 243 &&
                      renderSlots(11 - crowdfundingData.length, index + 1)}
                  </Carousel>
                </div>
                <img src={more} alt="More" className="w-4 d-none d-md-block" />
                <img src={arrow} alt="More" className="d-md-none" />
                <p className="h5 h4-sm h4-xl number-color mb-0 ml-2">243</p>
              </>
            )}
            {index === 5 && (
              <>
                <div className="square-729">
                  <Carousel
                    isRTL={false}
                    pagination={false}
                    showArrows={false}
                    itemsToShow={show729}
                    // enableAutoPlay
                    // autoPlaySpeed={1500}
                    breakPoints={breakPoints}
                  >
                    {crowdfundingData.map((crowdfunding) => (
                      <Slot
                        key={crowdfunding.id}
                        className="border-gray p-relative"
                      >
                        <Img
                          src={crowdfunding.avatar}
                          size={60}
                          borderWidth={0}
                          borderRadius={20}
                          onMouseEnter={(e) =>
                            handleMouseEnter(e, crowdfunding)
                          }
                          onMouseLeave={handleMouseLeave}
                        />
                      </Slot>
                    ))}
                    {crowdfundingData.length < 729 &&
                      renderSlots(13 - crowdfundingData.length, index + 1)}
                  </Carousel>
                </div>
                <img src={more} alt="More" className="w-4 d-none d-md-block" />
                <img src={arrow} alt="More" className="d-md-none" />
                <p className="h5 h4-sm h4-xl number-color mb-0 ml-2">729</p>
              </>
            )}
            {index === 6 && (
              <>
                {/* <div style={{ width: 1170 }}> */}

                <div className="square-2187">
                  <Carousel
                    isRTL={false}
                    pagination={false}
                    showArrows={false}
                    itemsToShow={show2187}
                    // itemsToShow={15}
                    // enableAutoPlay
                    // autoPlaySpeed={1500}
                    breakPoints={breakPoints}
                  >
                    {crowdfundingData.map((crowdfunding) => (
                      <Slot
                        key={crowdfunding.id}
                        className="border-gray p-relative"
                      >
                        <Img
                          src={crowdfunding.avatar}
                          size={60}
                          borderWidth={0}
                          borderRadius={20}
                          onMouseEnter={(e) =>
                            handleMouseEnter(e, crowdfunding)
                          }
                          onMouseLeave={handleMouseLeave}
                        />
                      </Slot>
                    ))}
                    {crowdfundingData.length < 2187 &&
                      renderSlots(15 - crowdfundingData.length, index + 1)}
                  </Carousel>
                </div>

                <img src={more} alt="More" className="w-4 d-none d-md-block" />
                <img src={arrow} alt="More" className="d-md-none" />
                <p className="h5 h4-sm h4-xl number-color mb-0 ml-2">2,187</p>
              </>
            )}
            {index === 7 && (
              <>
                {/* <div style={{ width: 1326 }}> */}
                <div className="square-6561">
                  <Carousel
                    isRTL={false}
                    pagination={false}
                    showArrows={false}
                    itemsToShow={show6561}
                    // itemsToShow={17}
                    // enableAutoPlay
                    // autoPlaySpeed={1500}
                    breakPoints={breakPoints}
                  >
                    {crowdfundingData.map((crowdfunding) => (
                      <Slot
                        key={crowdfunding.id}
                        className="border-gray p-relative"
                      >
                        <Img
                          src={crowdfunding.avatar}
                          size={60}
                          borderWidth={0}
                          borderRadius={20}
                          onMouseEnter={(e) =>
                            handleMouseEnter(e, crowdfunding)
                          }
                          onMouseLeave={handleMouseLeave}
                        />
                      </Slot>
                    ))}
                    {crowdfundingData.length < 6561 &&
                      renderSlots(17 - crowdfundingData.length, index + 1)}
                  </Carousel>
                </div>
                <img src={more} alt="More" className="w-4 d-none d-md-block" />
                <img src={arrow} alt="More" className="d-md-none" />
                <p className="h5 h4-sm h4-xl number-color mb-0 ml-2">6,561</p>
              </>
            )}
          </div>
        ))}
      </Container>
    </>
  );
};

export default Pyramid;
