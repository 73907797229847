import React, { useCallback, useRef, useState } from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { formatPrice } from '~/utils/format';
import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';

import { Modal } from './styles';
import Input from '../Input';
import InputMask from '../InputMask';
import InputCode from '../InputCode';
import { useLanguage } from '~/hooks/Language';

export interface IFormData {
  amount: string;
  token_2fa: string;
  cripto_address: string;
}

interface IWallet {
  id: string;
  amount: number;
  cripto_address: string;
}

interface IModalRetireMoneyProps {
  show: boolean;
  onHide: (value: boolean) => void;
  onSubmit?: (data: any) => void;
  wallet: IWallet;
}

const ModalRetireMoney: React.FC<IModalRetireMoneyProps> = ({
  onSubmit,
  wallet,
  ...props
}) => {
  const formRef = useRef<FormHandles>(null);
  const [code, setCode] = useState('');
  const [totalWithdraw, setTotalWithdraw] = useState('0.00');
  const [withdraw, setWithdraw] = useState('0.00');
  const [rate] = useState(4.5);
  const [codeError, setCodeError] = useState('');
  const [loading, setLoading] = useState(false);
  const { language } = useLanguage();

  const handleChangeCode = useCallback((e) => {
    const authenticationCode = e.target.value;
    setCode(authenticationCode);
  }, []);

  const handleChangeAmount = useCallback(
    (e) => {
      const amount = e.target.value.replaceAll(',', '');
      const partAmount = amount - (amount / 100) * rate;
      const total = formatPrice(amount);
      const part = formatPrice(partAmount);
      setTotalWithdraw(total);
      setWithdraw(part);
    },
    [rate]
  );

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          amount: Yup.string().required(language.wallet.modal_value),
          cripto_address: Yup.string().required(language.wallet.modal_address),
          token_2fa: Yup.string().when('$authenticationCode', {
            is: (authenticationCode: boolean) => !authenticationCode,
            then: Yup.string().required(language.wallet.modal_authentication),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: { authenticationCode: code.length === 6 },
        });

        const responseWallet = await api.get('wallets/affiliates');

        const value = parseFloat(
          data.amount.replace('$', '').replace(/,/g, '')
        );

        if (value > responseWallet.data.amount) {
          throw new Yup.ValidationError('');
        }

        if (onSubmit) {
          const { amount, cripto_address } = data;
          const amountData = amount as any;
          const formData = {
            amount: amountData.replace('$', '').replace(/,/g, ''),
            cripto_address,
            token_2fa: code,
          };

          onSubmit(formData);
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          let errors = getValidationErros(error);

          if (Object.keys(errors).length === 0) {
            errors = {
              amount: language.wallet.modal_value_2,
            };
          }

          if (errors.authentication_code) {
            setCodeError(errors.authentication_code);
          }
          formRef.current?.setErrors(errors);
        }
      } finally {
        setLoading(false);
      }
    },
    [
      code,
      language.wallet.modal_address,
      language.wallet.modal_authentication,
      language.wallet.modal_value,
      language.wallet.modal_value_2,
      onSubmit,
    ]
  );

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-signin"
    >
      <div className="container">
        <Modal.Header closeButton className="border-0 py-4" />
        <Modal.Body>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <div className="d-flex justify-content-center">
              <div className="h2 text-center text-sm-left">
                {language.wallet.modal_title}
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <p className="d-block font-weight-400 description text-center w-lg-75">
                {language.wallet.modal_p_1}
              </p>
            </div>
            <div className="d-flex justify-content-around">
              <p className="d-block font-weight-400 label w-100 w-lg-75 mt-5 mb-2">
                {language.wallet.modal_p_2}
              </p>
            </div>
            <div className="d-flex justify-content-center">
              <Input
                name="cripto_address"
                className="form-control d-block mt-1 mb-3 w-100 w-lg-75 rounded-3 input-lg h-100"
                value={wallet.cripto_address}
              />
            </div>
            <div className="d-flex justify-content-center">
              <p className="font-weight-400 d-block font-weight-400 label w-100 w-lg-75 mt-4 mb-0">
                {language.wallet.modal_p_3}
              </p>
            </div>
            <div className="d-flex justify-content-center">
              <small className="font-weight-400 d-block color w-100 w-lg-75 my-0">
                {language.wallet.modal_small}${wallet.amount}
              </small>
            </div>
            <div className="d-flex justify-content-center">
              <div className="d-block w-100 w-lg-75">
                <div className="input-group mg-error my-2">
                  <span className="input-group-text pl-4">$</span>

                  <InputMask
                    kind="money"
                    name="amount"
                    options={{
                      unit: '',
                      separator: '.',
                      delimiter: ',',
                    }}
                    onChange={handleChangeAmount}
                    className="form-control h-100"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <p className="font-weight-400 d-block label w-100 w-lg-75 mt-5 mb-2">
                {language.wallet.modal_p_4}
              </p>
            </div>
            <div className="mt-1 d-flex justify-content-center">
              <div className="d-block w-100 w-lg-75">
                <InputCode
                  name="token_2fa"
                  onChange={handleChangeCode}
                  error={codeError}
                />
              </div>
            </div>
            <div className="d-flex-column d-sm-flex w-100 w-lg-75 mt-5 mx-auto justify-content-center mt-2">
              <div className="d-block w-100 w-lg-75">
                <div className="d-flex justify-content-center justify-content-sm-start mb-4">
                  <span className="font-weight-400 text-center text-sm-left data">
                    {language.wallet.modal_span_1}

                    <br />
                    <span className="value total-withdraw">
                      {totalWithdraw} USD
                    </span>
                  </span>
                </div>
              </div>
              <div className="d-block w-100 w-lg-75 ">
                <div className="d-flex justify-content-center justify-content-sm-end">
                  <span className="font-weight-400 data">
                    {language.wallet.modal_span_2}{' '}
                    <span className="value">{rate}%</span>
                  </span>
                </div>
                <div className="d-flex justify-content-center justify-content-sm-end">
                  <span className="font-weight-400 data">
                    {language.wallet.modal_span_3}{' '}
                    <span className="value">{withdraw}</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex w-100 w-sm-75 mx-auto my-3 justify-content-center">
              <div className="d-block w-100 w-lg-75">
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    className="h5 d-block mt-3 w-100 w-sm-75 btn-submit btn btn-cancel py-3 mx-2 mx-sm-0"
                    onClick={() => props.onHide(false)}
                  >
                    {language.wallet.modal_button_1}
                  </button>
                </div>
              </div>
              <div className="d-block w-100 w-lg-75">
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    className="h5 d-block btn-submit mt-3 w-100 w-sm-75 py-3 btn btn-confirm mx-2 mx-sm-0"
                  >
                    {language.wallet.modal_button_2}
                  </button>
                </div>
              </div>
            </div>

            {/* <div className="d-flex mt-3 justify-content-center">
              <div className="d-block w-100 w-lg-75">
                <hr />
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <p className="font-weight-400 text-center description mb-5">
                {language.wallet.modal_p_5}
              </p>
            </div> */}
          </Form>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default ModalRetireMoney;
