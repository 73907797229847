import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

import api from '~/services/api';
import { formatPrice } from '~/utils/format';
import { useLanguage } from '~/hooks/Language';

import { Container, Welcome, Values, Table, Avatar, Others } from './styles';

import student from '~/assets/icons/student-icon.svg';
import affiliate from '~/assets/icons/affiliate-icon.svg';
import franchise from '~/assets/icons/franchise-purple-icon.svg';

interface IStudent {
  id: string;
  name: string;
  email: string;
  referral_code: string;
  avatar: {
    id: string;
    avatar_url: string;
  };
  affiliate: {
    id: string;
    active_affiliate: boolean;
    franchise_owner: boolean;
    product?: {
      id: string;
      title: string;
    };
  };
}

interface IReferrerResponse {
  id: string;
  student: {
    id: string;
    name: string;
    email: string;
    referral_code: string;
    avatar: {
      id: string;
      avatar_url: string;
    };
    affiliate: {
      id: string;
      active_affiliate: boolean;
      franchise_owner: boolean;
      product?: {
        id: string;
        title: string;
      };
    };
    orders: IOrder[];
  };
  created_at: string;
}

interface IReferrer {
  id: string;
  avatar: string;
  name: string;
  email: string;
  type: string;
  typeIcon: string;
  product: string;
  joined: string;
}

interface IOrder {
  id: string;
  amount_paid: string;
  status: string;
  course?: {
    id: string;
    title: string;
  };
}

interface IParams {
  slug: string;
}

const TeamView: React.FC = () => {
  const { language } = useLanguage();
  const params = useParams<IParams>();
  const [loading, setLoading] = useState(false);
  const [referrer, setReferrer] = useState<IStudent>({} as IStudent);
  const [referrers, setReferrers] = useState<IReferrer[]>([]);
  const [total, setTotal] = useState('0.00');
  const [typeUser, setTypeUser] = useState('Student');
  const [typeIconUser, setTypeIconUser] = useState(student);

  useEffect(() => {
    setLoading(true);
    let totalData = 0;
    api
      .get<IStudent>(`students/${params.slug}`)
      .then(async (responseStudent) => {
        const response = await api.get<IReferrerResponse[]>(
          `referrers/${responseStudent.data.affiliate.id}`
        );
        const data = response.data.map((referrerData) => {
          const nameParts = referrerData.student.name.split(' ');
          const joined = `Joined ${format(
            parseISO(referrerData.created_at),
            'MMM dd, yyyy'
          )}`;
          let type = 'Student';
          let typeIcon = student;
          let product =
            referrerData.student.orders[
              referrerData.student.orders.length - 1
            ] &&
            referrerData.student.orders[referrerData.student.orders.length - 1]
              .course?.title;

          totalData = referrerData.student.orders.reduce(
            (acumulador: number, currentValue: IOrder) => {
              if (currentValue.status === 'succeeded') {
                return acumulador + parseFloat(currentValue.amount_paid);
              }
              return acumulador + 0;
            },
            totalData
          );

          if (referrerData.student.affiliate.active_affiliate) {
            if (referrerData.student.affiliate.franchise_owner) {
              type = 'Franchise Owner';
              typeIcon = franchise;
              product = referrerData.student.affiliate.product?.title;
            } else {
              type = 'Affiliate';
              typeIcon = affiliate;
              product =
                referrerData.student.orders[
                  referrerData.student.orders.length - 1
                ].course?.title;
            }
          }
          return {
            id: referrerData.id,
            avatar: referrerData.student.avatar.avatar_url,
            name: `${nameParts[0]} ${nameParts[nameParts.length - 1][0]}.`,
            email: referrerData.student.email,
            type,
            typeIcon,
            product: product || 'N/A',
            joined,
          };
        });

        if (responseStudent.data.affiliate.active_affiliate) {
          if (responseStudent.data.affiliate.franchise_owner) {
            setTypeUser('Franchise Owner');
            setTypeIconUser(franchise);
          } else {
            setTypeUser('Affiliate');
            setTypeIconUser(affiliate);
          }
        }

        setReferrer(responseStudent.data);
        setReferrers(data);
      })
      .finally(() => {
        setTotal(formatPrice(totalData));
        setLoading(false);
      });
  }, [params.slug]);

  return (
    <>
      <Container className="container px-0">
        <div className="w-100">
          <Welcome>
            <div className="row">
              <div className="col-12 my-4">
                <h1 className="h4 h2-sm text-center text-lg-left">
                  {language.my_referrals.h1}
                </h1>
              </div>
            </div>
          </Welcome>
          {Object.keys(referrer).length > 0 && (
            <>
              <div className="row justify-content-center">
                <Values className="col-11 col-xxl-10 mb-n5 d-md-flex justify-content-center mt-3 py-4">
                  <div className="col-lg-4 text-center border-md-right">
                    <p className="h4 mb-0 mb-sm-3">
                      <span>{referrers.length}</span>
                    </p>
                    <p className="mb-0 dark-gray">{language.team_view.p_1}</p>
                  </div>

                  <hr className="w-25 mx-auto d-md-none" />
                  <div className="col-lg-4 text-center">
                    <p className="h4 mb-0 mb-sm-3">
                      <span>{total}</span>
                    </p>
                    <p className="mb-0 dark-gray">Personal Volume Total</p>
                  </div>

                  {/* <hr className="w-25 mx-auto d-md-none" />
                  <div className="col-lg-3 text-center border-md-right">
                    <p className="h4 mb-0 mb-sm-3">
                      <span>$5,985</span>
                    </p>
                    <p className="mb-0 dark-gray">{language.team_view.p_3}</p>
                  </div>

                  <hr className="w-25 mx-auto d-md-none" />
                  <div className="col-lg-3 text-center">
                    <p className="h4 mb-0 mb-sm-3">
                      <span>$24,264</span>
                    </p>
                    <p className="mb-0 dark-gray">{language.team_view.p_4}</p>
                  </div> */}
                </Values>
              </div>

              <Table className="py-5">
                <div className="col-12 my-5 d-flex flex-column align-items-center">
                  <Avatar
                    src={referrer.avatar.avatar_url}
                    className="mt-5 mb-4"
                  />
                  <div className="d-flex align-items-center mb-2">
                    <img src={typeIconUser} alt="Fire Ball" />
                    <p className="mb-0 pl-3 font-weight-600">{typeUser}</p>
                  </div>
                  <p className="mb-2 font-weight-600">
                    {referrer.referral_code}
                  </p>
                  <p className="mb-0 font-weight-400 text-color">
                    {referrer.name}
                  </p>
                </div>
                <div className="overflow-auto scroll">
                  <div className="w-content d-flex pt-5 mb-4">
                    {referrers.map((referrerData) => (
                      <div key={referrerData.id} className="text-center mx-3">
                        <Others src={referrerData.avatar} />
                        <p className="font-weight-400 mt-2">
                          {referrerData.name}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </Table>
            </>
          )}
        </div>
      </Container>
      {loading && (
        <>
          <div className="loading-box">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TeamView;
