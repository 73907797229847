import styled, { css } from 'styled-components';

interface IImgProps {
  src: string;
  size: number;
  borderWidth: number;
  borderRadius: number;
  hasShadow?: boolean;
}

interface ICardProfileProps {
  myProfile?: boolean;
}

export const Container = styled.div`
  /* margin-top: -17rem; */
  margin-top: -10.5rem;

  z-index: 80;

  .row-profiles {
    .first-profile {
      width: 95px;
      height: 95px;
      border: none;

      img {
        filter: drop-shadow(3px 6px 9px rgba(0, 0, 0, 0.35));
        border-radius: 30px;
        border: solid 3px #cbccce;
      }
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      border-radius: 20px;
      margin: 5px;
      padding: 2px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
      }
    }
  }

  .number-color {
    color: #dadada;
  }

  .active {
    > div {
      opacity: 1;
      visibility: visible;
    }
  }

  .square-3 {
    width: 243px;
  }

  .square-9 {
    width: 390px;
  }

  .square-27 {
    width: 546px;
  }

  .square-81 {
    width: 705px;
  }

  .square-243 {
    width: 858px;
  }

  .square-729 {
    width: 1012px;
  }

  .square-2187 {
    width: 1089px;
  }

  .square-6561 {
    width: 1089px;
  }

  @media screen and (max-width: 1600px) {
    .square-6561 {
      width: 1091px;
    }
  }

  @media screen and (max-width: 1499px) {
    .square-729,
    .square-2187,
    .square-6561 {
      width: 936px;
    }
  }

  @media screen and (max-width: 1300px) {
    .square-243,
    .square-729,
    .square-2187,
    .square-6561 {
      width: 781px;
    }
  }

  @media screen and (max-width: 1100px) {
    .square-243,
    .square-729,
    .square-2187,
    .square-6561 {
      width: 704px;
    }
  }

  @media screen and (max-width: 991px) {
    margin-top: 0;
    .square-81,
    .square-243,
    .square-729,
    .square-2187,
    .square-6561 {
      width: 547px;
    }
  }

  @media screen and (max-width: 768px) {
    .square-27,
    .square-81,
    .square-243,
    .square-729,
    .square-2187,
    .square-6561 {
      width: 390px;
    }
  }

  @media screen and (max-width: 575px) {
    .square-3,
    .square-9,
    .square-27,
    .square-81,
    .square-243,
    .square-729,
    .square-2187,
    .square-6561 {
      width: 200px;
    }
  }

  @media screen and (min-width: 1200px) {
    margin-top: -12.5rem;
  }
  @media screen and (min-width: 1400px) {
    .hd-plus-show {
      display: flex !important;
    }
  }

  @media screen and (min-width: 1600px) {
    .full-hd-show {
      display: flex !important;
    }
  }

  @media screen and (min-width: 1800px) {
  }
`;

export const Img = styled.div<IImgProps>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: ${(props) => props.borderRadius}px;
  box-shadow: inset 0px 0px 0px ${(props) => props.borderWidth}px
    rgba(228, 228, 228, 0.5);
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;

  ${(props) =>
    props.hasShadow &&
    css`
      filter: drop-shadow(3px 6px 9px rgba(0, 0, 0, 0.35));
    `}
`;

export const ImgCard = styled.div<IImgProps>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: ${(props) => props.borderRadius}px;
  box-shadow: inset 0px 0px 0px ${(props) => props.borderWidth}px
    rgba(228, 228, 228, 0.5);
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;

  ${(props) =>
    props.hasShadow &&
    css`
      filter: drop-shadow(3px 6px 9px rgba(0, 0, 0, 0.35));
    `}

  @media screen and (max-width: 991px) {
    width: 60px !important;
    height: 60px !important;
    border-radius: 20px !important;
    box-shadow: inset 0px 0px 0px 2px rgba(228, 228, 228, 0.5) !important;
  }
`;

export const Slot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 20px !important;
  margin: 5px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    border-radius: 17px;
  }

  @media screen and (max-width: 575px) {
    width: 50px;
    height: 50px;
    border-radius: 15px !important;
  }
`;

export const CardProfile = styled.div<ICardProfileProps>`
  filter: drop-shadow(0px 4px 100px rgba(0, 0, 0, 0.6));
  width: ${(props) => (props.myProfile ? '300%' : '291px')};
  height: 316px;
  background: #242526;
  border-radius: 30px;
  z-index: 500;
  opacity: 0;
  visibility: hidden;
  transition-duration: 0.3s;

  p {
    color: #606060;
  }

  hr {
    border-color: #4a4b4b;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  img {
    width: 95px !important;
    height: 95px !important;
    border-radius: 50% !important;
  }

  img.fireBall {
    width: 20px !important;
    height: 30px !important;
  }

  .text-golden {
    background: -webkit-linear-gradient(-90deg, #825511 0%, #f3bf5c 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media screen and (max-width: 992px) {
    width: ${(props) => (props.myProfile ? '300%' : '240px')};
    height: 300px;
  }

  @media screen and (max-width: 380px) {
    width: ${(props) => (props.myProfile ? '300%' : '195px')};
  }
`;
