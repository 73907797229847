import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  textarea {
    border-radius: 20px;
  }

  h1,
  a {
    color: #e4e4e4;
  }

  h3 {
    color: #cbccce;
  }

  p {
    color: #606060;
    font-weight: 400;
  }

  .bg-text {
    background-color: #202020;
    color: #606060;
  }

  .old-price {
    color: #ff1a50;
  }
`;

export const Courses = styled.div`
  .thumb {
    border-radius: 20px;
    height: 230px;
  }

  a {
    height: 100%;
    border-radius: 20px;

    .short-title {
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 0px 0px 20px 20px;

      p {
        color: #fff;
      }
    }
  }
`;
