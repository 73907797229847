import styled from 'styled-components';

export const Container = styled.div`
  h2.text-gray {
    color: #ababab;
  }
`;

export const Welcome = styled.div`
  h1 {
    color: #e4e4e4;
  }
  p {
    color: #858585;
    font-weight: 300;
  }

  .search {
    padding: 1px;
    border-radius: 100px;
    border: 1px solid #4a4b4b;
    background-color: #202020;

    input {
      margin: 5px 0;
      padding: 0 10px;
      border: none;
      border-radius: 100px;
      background-color: #202020;
    }
  }

  .border-golden {
    :hover {
      color: #e4e4e4;
      text-decoration: none;
    }
  }
`;

export const Values = styled.div`
  .bg-gray {
    background: #242526;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }

  p {
    color: #cbccce;
  }

  .dark-gray {
    color: #606060;
  }

  @media screen and (max-width: 991px) {
    hr {
      background-color: #606060;
      height: 2px;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: 768px) {
    .border-md-right {
      border-right: 2px solid #606060;
    }
  }

  @media screen and (min-width: 1200px) {
    .border-right-lg-0 {
      border-right: none;
    }
  }
`;

export const Distribution = styled.div`
  button {
    color: #828282;
    border: none;
    border: 1px solid #242526;
    :hover,
    :focus {
      color: #f5f6f8;
      border: 1px solid #e4e4e4;
    }
  }

  .bg-box {
    background-color: #18191a;
    border-radius: 20px;
    p {
      color: #a9a9a9;
    }
  }

  .color {
    color: #cbccce;
  }

  .bg-gray {
    background: #242526;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }
`;

export const PyramidData = styled.div`
  position: unset;
  background: #202020;
  border-radius: 20px;

  p {
    color: #828282;
    font-size: 12px;
  }

  h4 {
    color: #cbccce;
  }

  .bg-gray {
    background: #242526;
    border-radius: 20px;
    z-index: 81;
  }
`;

export const Graph = styled.div`
  background: #242526;
  border-radius: 20px;
  padding: 25px;
`;
