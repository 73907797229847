import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { FiArrowLeft } from 'react-icons/fi';
import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';

import { Container } from './styles';
import Player from '~/components/Player';
import Notes from '~/components/Notes';

interface IParams {
  slug: string;
}

interface ITrainingResponse {
  id: string;
  title: string;
  thumbnail: {
    thumbnail_url: string;
  };
  slug: string;
}

interface ITraining {
  id: string;
  title: string;
  description: string;
  thumbnail: string;
  video: string;
  slug: string;
}

interface IOtherTraining {
  id: string;
  title: string;
  thumbnail: string;
  slug: string;
}

const Course: React.FC = () => {
  const { language } = useLanguage();
  const params = useParams<IParams>();
  const [training, setTraining] = useState<ITraining>({} as ITraining);
  const [trainings, setTrainings] = useState<IOtherTraining[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api.get(`trainings/students/${params.slug}`).then((response) => {
      const data = {
        id: response.data.id,
        title: response.data.title,
        description: response.data.description,
        thumbnail: response.data.thumbnail.thumbnail_url,
        slug: response.data.slug,
        video: response.data.video.media_id
          ? response.data.video.video_url
          : `https://cdn.jwplayer.com/videos/${response.data.video.media_id}.mp4`,
      };
      setTraining(data);
      setLoading(false);
    });
  }, [params.slug]);

  useEffect(() => {
    if (training) {
      api.get<ITrainingResponse[]>(`/other/${training.id}`).then((response) => {
        const data = response.data.map((trainingData) => ({
          id: trainingData.id,
          title: trainingData.title,
          thumbnail: trainingData.thumbnail.thumbnail_url,
          slug: trainingData.slug,
        }));
        setTrainings(data);
      });
    }
  }, [training]);

  return (
    <>
      <Container>
        <div className="container py-4">
          <div className="row">
            <div className="col-12 order-0">
              <div className="d-flex title align-items-center">
                <Link
                  to={`${process.env.PUBLIC_URL}/trainings`}
                  className="mb-4 mr-4"
                >
                  <FiArrowLeft size={30} />
                </Link>
                <h1 className="h2 h1-lg mb-4">{training.title}</h1>
              </div>
            </div>
            <div className="col-lg-8 order-1">
              <Player src={training.video} className="video-size" />
            </div>
            <div className="col-md-6 col-lg-4 order-4 order-lg-3 mt-5 mt-lg-0">
              <Notes />
            </div>
            <div className="col-md-6 col-lg-8 mt-5 order-3 order-lg-4">
              <h3 className="h4">{language.training.h3}</h3>
              <p className="h6 mt-4">{training.description}</p>
            </div>
          </div>
        </div>
        {loading && (
          <div className="loading-box">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default Course;
