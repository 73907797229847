import React, { createContext, useCallback, useState, useContext } from 'react';

import api from '../services/api';

interface IAffiliatesProducts {
  id: string;
  product_id: string;
  product: {
    name: string;
    lines: number;
  };
}
export interface User {
  id: string;
  name: string;
  email: string;
  username: string;
  phone: string;
  referral_code: string;
  email_notifications: boolean;
  sms_notifications: boolean;
  avatar: {
    avatar_url: string;
  };
  address: string;
  affiliate: {
    id: string;
    student_id: string;
    product_id: string;
    active_affiliate: boolean;
    affiliate_commission: number;
    crowdfunding_participation: boolean;
    crowdfunding_share: number;
    franchise_owner: boolean;
    franchise_commission: number;
    affiliatesProducts?: IAffiliatesProducts[];
    wallet: {
      id: string;
      amount: string;
    };
  };
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface IWalletHistory {
  type: string;
  date: string;
  amount: string;
  withdrawal?: boolean;
}

interface IGiftCard {
  id: string;
  status: string;
  card: string;
  amount: string;
}

interface AuthContextData {
  user: User;
  walletPrice: number;
  setWalletPrice(amount: number): void;
  walletHistory: IWalletHistory[];
  setWalletHistory(walletHistory: IWalletHistory[]): void;
  giftCards: IGiftCard[];
  setGiftCards(giftCards: IGiftCard[]): void;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: User): void;
  setLogin(token: string, user: User, cacheToken: string): void;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Membership:token');
    const user = localStorage.getItem('@Membership:user');

    if (token && user) {
      api.defaults.headers.authorization = `Barear ${token}`;
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });
  const [walletPrice, setWalletPrice] = useState(0);
  const [walletHistory, setWalletHistory] = useState<IWalletHistory[]>([]);
  const [giftCards, setGiftCards] = useState<IGiftCard[]>([]);

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post<AuthState>('students/sessions', {
      email,
      password,
    });

    const { token, user } = response.data;

    localStorage.setItem('@Membership:token', token);
    localStorage.setItem('@Membership:user', JSON.stringify(user));

    api.defaults.headers.authorization = `Barear ${token}`;

    setData({ token, user });
  }, []);

  const signOut = useCallback(() => {
    const cacheToken = localStorage.getItem('@Membership:cacheToken');
    localStorage.removeItem('@Membership:token');
    localStorage.removeItem('@Membership:user');
    localStorage.removeItem('@Membership:cacheToken');

    if (cacheToken) {
      api.get(`affiliates/sessions/remove-data/${cacheToken}`);
    }

    setData({} as AuthState);

    window.location.href = `${process.env.REACT_APP_STUDENTS_URL}/sign-out`;
  }, []);

  const updateUser = useCallback(
    (user) => {
      localStorage.setItem('@Membership:user', JSON.stringify(user));

      setData({
        token: data.token,
        user,
      });
    },
    [setData, data.token]
  );

  const setLogin = useCallback(
    (token, user, cacheToken) => {
      localStorage.setItem('@Membership:token', token);
      localStorage.setItem('@Membership:user', JSON.stringify(user));
      localStorage.setItem('@Membership:cacheToken', cacheToken);

      api.defaults.headers.authorization = `Barear ${token}`;

      setData({
        token,
        user,
      });
    },
    [setData]
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        walletPrice,
        setWalletPrice,
        walletHistory,
        setWalletHistory,
        giftCards,
        setGiftCards,
        signIn,
        signOut,
        updateUser,
        setLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
