import styled from 'styled-components';

interface ISeparatorProps {
  noPadding: boolean;
  fullSize: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  background: #242526;
  min-height: 100vh;

  @media screen and (min-width: 992px) {
    .separator-container {
      width: calc(100% - 90px);
    }
  }

  @media screen and (max-width: 991px) {
    display: block;
  }
`;

export const HeaderSpacer = styled.div`
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media screen and (max-width: 991px) {
    width: 100%;
    height: 60px;
    justify-content: flex-start;
  }
`;

export const Separator = styled.div<ISeparatorProps>`
  background-color: #18191a;
  width: ${(props) => (props.fullSize ? '100%' : 'calc(100% - 298px)')};
  padding: 20px;

  @media screen and (min-width: 1400px) {
    width: ${(props) => (props.fullSize ? '100%' : 'calc(100% - 322px)')};
  }

  @media screen and (max-width: 1199px) {
    width: 100%;
    padding: ${(props) => (props.noPadding ? '0px' : '8px')};
  }

  @media screen and (max-width: 575px) {
    padding: 15px;
  }
`;

export const FooterSpacer = styled.div`
  background: #202020;

  @media screen and (max-width: 1199px) {
    width: 100%;
    background: #18191a;
  }

  @media screen and (min-width: 1200px) {
    min-width: 270px;
    max-width: 298px;
  }

  @media screen and (min-width: 1400px) {
    min-width: 308px;
    max-width: 322px;
  }
`;
