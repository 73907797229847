import styled, { css } from 'styled-components';

import dashboard from '~/assets/icons/dashboard-icon.svg';
import dashboardActive from '~/assets/icons/dashboard-icon-active.svg';
import commission from '~/assets/icons/commission-icon.svg';
import commissionActive from '~/assets/icons/commission-icon-active.svg';
import training from '~/assets/icons/training-icon.svg';
import trainingActive from '~/assets/icons/training-icon-active.svg';
import socialMedia from '~/assets/icons/social-media-icon.svg';
import socialMediaActive from '~/assets/icons/social-media-icon-active.svg';
import crowdFunding from '~/assets/icons/crowdfunding-icon.svg';
import crowdFundingActive from '~/assets/icons/crowdfunding-icon-active.svg';
import courses from '~/assets/icons/courses-icon.svg';
import coursesActive from '~/assets/icons/courses-icon-active.svg';
import affiliates from '~/assets/icons/affiliates-icon.svg';
import affiliatesActive from '~/assets/icons/affiliates-icon-active.svg';
import wallets from '~/assets/icons/wallet-icon.svg';
import walletsActive from '~/assets/icons/wallet-icon-active.svg';
import referrals from '~/assets/icons/my-referrals-icon.svg';
import referralsActive from '~/assets/icons/my-referrals-icon-active.svg';

import logout from '~/assets/icons/log-out-icon.svg';
import logoutActive from '~/assets/icons/log-out-icon-active.svg';

interface IMenuProps {
  active: boolean;
}

export const Container = styled.header`
  position: fixed;
  top: 0;
  background: #242526;
  z-index: 100;

  .btn-idiom {
    color: #606060 !important;
    img {
      width: 22px;
      height: 22px;
      transition-duration: 0.3s;
      filter: grayscale(1);
      border-radius: 50%;
    }

    :hover img,
    .active-idiom {
      filter: grayscale(0);
    }
    :hover,
    :focus {
      color: #dadada !important;
    }
  }

  .flag-text-light {
    color: #dadada !important;
  }

  @media screen and (max-width: 991px) {
    height: 60px;
  }
`;

export const Menu = styled.div<IMenuProps>`
  > button {
    width: 30px;
    height: 30px;

    span {
      display: block;
      width: 100%;
      height: 4px;
      background-color: #dadada;
      border-radius: 30px;
      transition-duration: 0.5s;

      :nth-child(2) {
        width: 90%;
        margin: 6px 0 6px auto;
      }

      ${(props) =>
        props.active &&
        css`
          :nth-child(1) {
            transform: rotate(225deg);
          }

          :nth-child(2) {
            opacity: 0;
            transform: rotate(225deg);
          }

          :nth-child(3) {
            transform: rotate(315deg);
            margin-top: -20px;
          }
        `}
    }
  }

  .min-height {
    min-height: 50px;
  }

  button:focus {
    outline: none;
  }

  .menu-group {
    height: calc(100vh - 100px);
  }

  .menu,
  .logoff {
    a,
    button {
      background: #242526;
      color: #dadada;
      position: relative;

      div {
        width: 22px;
        height: 22px;
        margin: 0 auto;
        transition-duration: 0.3s;
      }

      .dashboard {
        background-image: url(${dashboard});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .commission-history {
        background-image: url(${commission});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .trainings {
        background-image: url(${training});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .social-media {
        background-image: url(${socialMedia});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .affiliates {
        background-image: url(${affiliates});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .crowdfunding {
        background-image: url(${crowdFunding});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .courses {
        background-image: url(${courses});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .wallets {
        background-image: url(${wallets});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .referrals {
        background-image: url(${referrals});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .logout {
        background-image: url(${logout});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      small {
        width: max-content;
        position: absolute;
        transition-duration: 0.3s;
        z-index: -1;
        left: -100%;
        background: #242526;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 5px 5px 0;
      }
    }

    .become-affiliate-link {
      line-height: 1;
    }

    a:hover,
    button:hover,
    a.active {
      text-decoration: none;

      .dashboard {
        background-image: url(${dashboardActive});
      }

      .courses {
        background-image: url(${coursesActive});
      }

      .commission-history {
        background-image: url(${commissionActive});
      }

      .trainings {
        background-image: url(${trainingActive});
      }

      .social-media {
        background-image: url(${socialMediaActive});
      }

      .affiliates {
        background-image: url(${affiliatesActive});
      }

      .crowdfunding {
        background-image: url(${crowdFundingActive});
      }

      .wallets {
        background-image: url(${walletsActive});
      }

      .referrals {
        background-image: url(${referralsActive});
      }

      .logout {
        background-image: url(${logoutActive});
      }
    }

    a:hover,
    button:hover {
      small {
        padding: 0 20px;
        left: 100%;

        @media screen and (min-width: 992px) {
          min-height: 50px;
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    .menu-group {
      width: 100%;
      height: calc(100vh - 60px);
      background: #242526;
      transition-duration: 0.3s;
      top: 60px;
      left: ${(props) => (props.active ? '0px' : '1000px')};
    }

    .overflow {
      overflow: auto;
      ::-webkit-scrollbar {
        background-color: #242526 !important;
        width: 5px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #242526 !important;
      }
    }

    .menu,
    .logoff {
      a,
      button {
        div {
          margin: 0 10px;
          width: 22px;
          height: 22px;
        }

        small {
          position: relative;
          z-index: 0;
          text-align: left;
          opacity: 1;
          font-size: 24px;
          justify-content: start;
          left: 0 !important;
          padding: 5px 0 !important;
        }
      }
    }
  }
`;
