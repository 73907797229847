import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { format, parseISO } from 'date-fns';
import { NavLink, useParams, useLocation } from 'react-router-dom';
import { HiOutlineTrash } from 'react-icons/hi';

import { useAuth } from '~/hooks/Auth';
import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';

import { Container, Price, DeleteButton } from './styles';
import Table from '~/components/Table';

interface ITransaction {
  id: string;
  amount: number;
  type: string;
  created_at: string;
}

interface IGiftCard {
  id: string;
  hash: string;
  price: string;
  used: string;
}

interface ITableData {
  type: string;
  date: string;
  amount: string;
}

interface ITableDataGift {
  id: string;
  status: string;
  card: string;
  amount: string;
}

interface ITransactionHistory {
  updateData: number;
}

interface IParams {
  slug: string;
}

const TransactionHistory: React.FC<ITransactionHistory> = ({ updateData }) => {
  const params = useParams<IParams>();
  const { language } = useLanguage();
  const location = useLocation();
  const {
    user,
    walletHistory,
    setWalletHistory,
    giftCards,
    setGiftCards,
    setWalletPrice,
  } = useAuth();
  const [page, setPage] = useState('transactions');
  const [walletsHistory, setWalletsHistory] = useState<ITableData[]>([]);
  const [lang] = useState(() => {
    const langSymbol = location.pathname.substr(0, 3);
    if (langSymbol !== '/en' && langSymbol !== '/es') {
      return ``;
    }

    return langSymbol;
  });

  useEffect(() => {
    setPage(params.slug);
  }, [params.slug]);

  useEffect(() => {
    if (page === 'transactions') {
      api
        .get<ITransaction[]>(
          `wallets-history/wallets/${user.affiliate.wallet.id}`
        )
        .then((response) => {
          const data: ITableData[] = response.data.map((walletHistoryData) => ({
            type: walletHistoryData.type,
            date: format(parseISO(walletHistoryData.created_at), 'yyyy-MM-dd'),
            amount: formatPrice(walletHistoryData.amount),
            withdrawal: walletHistoryData.amount < 0,
          }));
          setWalletHistory(data);
        });
    } else {
      api.get<IGiftCard[]>('gift-cards/students').then((response) => {
        const data = response.data.map((giftCard) => ({
          id: giftCard.id,
          status: giftCard.used ? 'Used' : 'Unused',
          card: giftCard.hash,
          amount: formatPrice(parseFloat(giftCard.price)),
        }));

        setGiftCards(data);
      });
    }
  }, [
    page,
    setGiftCards,
    setWalletHistory,
    updateData,
    user.affiliate.wallet.id,
  ]);

  useEffect(() => {
    if (page === 'transactions') {
      setWalletsHistory(walletHistory);
    }
  }, [page, walletHistory]);

  const handleClickDelete = useCallback(
    async (giftCardId) => {
      try {
        const response = await api.delete(`gift-cards/students/${giftCardId}`);
        if (response.data) {
          const dataWalletHistory = {
            type: response.data.walletHistory.type,
            date: format(
              parseISO(response.data.walletHistory.created_at),
              'yyyy-MM-dd'
            ),
            amount: formatPrice(response.data.walletHistory.amount),
            withdrawal: response.data.walletHistory.amount < 0,
          };

          const newGiftCards = giftCards.filter(
            (giftCard) => giftCard.id !== giftCardId
          );

          setWalletPrice(response.data.wallet.amount);
          setWalletHistory([dataWalletHistory, ...walletHistory]);
          setGiftCards(newGiftCards);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [giftCards, setGiftCards, setWalletHistory, setWalletPrice, walletHistory]
  );

  const columns = useMemo(() => {
    if (page === 'transactions') {
      return [
        {
          name: 'Tipo',
          selector: 'type',
          sortable: true,
        },
        {
          name: 'Data',
          selector: 'date',
          sortable: true,
        },
        {
          name: 'Quantia',
          selector: 'amount',
          sortable: true,
          cell: (row: any) => (
            <Price withdrawal={row.withdrawal} className="mb-0">
              {row.amount}
            </Price>
          ),
        },
      ];
    }

    return [
      {
        name: 'Status',
        selector: 'status',
        sortable: true,
      },
      {
        name: 'Card',
        selector: 'card',
        sortable: true,
      },
      {
        name: 'Quantia',
        selector: 'amount',
        sortable: true,
        cell: (row: any) => (
          <Price withdrawal={row.withdrawal} className="mb-0">
            {row.amount}
          </Price>
        ),
      },
      {
        name: '',
        selector: 'id',
        sortable: false,
        cell: (row: ITableDataGift) => (
          <>
            {row.status === 'Unused' && (
              <DeleteButton
                type="button"
                onClick={() => handleClickDelete(row.id)}
              >
                <HiOutlineTrash size={24} color="#FF1A50" />
              </DeleteButton>
            )}
          </>
        ),
      },
    ];
  }, [handleClickDelete, page]);

  const handleClick = useCallback((pageSelected) => {
    setPage(pageSelected);
  }, []);

  return (
    <Container>
      <div className="d-flex mb-n4 mt-3 p-relative link-group">
        <NavLink
          to={`${process.env.PUBLIC_URL}${lang}/wallet/transactions`}
          activeClassName="active"
          className="px-3 font-weight-600"
          onClick={() => handleClick('transactions')}
        >
          {language.wallet.navLink_1}
        </NavLink>
        <NavLink
          to={`${process.env.PUBLIC_URL}${lang}/wallet/gift-cards`}
          activeClassName="active"
          className="px-3 font-weight-600"
          onClick={() => handleClick('gift-cards')}
        >
          {language.wallet.navLink_2}
        </NavLink>
      </div>
      {page === 'transactions' ? (
        <Table
          columns={columns}
          data={walletsHistory}
          pagination
          title="Historico de Transações"
          date
          exportable
        />
      ) : (
        <Table
          columns={columns}
          data={giftCards}
          pagination
          title="Gift cards"
          date
          exportable
        />
      )}
    </Container>
  );
};

export default TransactionHistory;
