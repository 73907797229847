import React, { useCallback, useEffect, useState } from 'react';
import { useLanguage } from '~/hooks/Language';

import api from '~/services/api';

import { Container, Welcome, Option, Board } from './styles';
import TransactionHistory from '~/components/TransactionHistory';
import MyEarnings from '~/components/MyEarnings';

import calendar from '~/assets/icons/calendar-wallet.svg';
import watch from '~/assets/icons/watch-wallet.svg';
import { formatPrice } from '~/utils/format';

interface ICommission {
  id: string;
  earning: string;
  created_at: string;
}

const Wallet: React.FC = () => {
  const { language } = useLanguage();
  const [update, setUpdate] = useState(0);
  const [availableSoon, setAvailableSoon] = useState('$0.00');

  useEffect(() => {
    api
      .get('commissions', {
        params: {
          type: 'lock',
        },
      })
      .then((response) => {
        const gains = response.data.reduce(
          (acumulador: number, currentValue: ICommission) => {
            return acumulador + parseFloat(currentValue.earning);
          },
          0
        );

        setAvailableSoon(formatPrice(gains));
      });
  }, []);

  const handleUpdate = useCallback(() => {
    setUpdate((state) => state + 1);
  }, []);

  return (
    <Container className="d-flex align-items-center">
      <div className="container">
        <div className="row mb-4 mt-4">
          <div className="col-12 mb-md-2">
            <Welcome>
              <div className="d-flex justify-content-center justify-content-lg-start">
                <div className="mr-2">
                  <span className="h6-xs h4 h2-lg font-weight-bold">
                    <Option>
                      <h1 className="h4 h2-sm">{language.wallet.h1}</h1>
                    </Option>
                  </span>
                </div>
              </div>
            </Welcome>
          </div>
        </div>

        <div className="row">
          <div className="bg-gray col p-3 p-xxl-5">
            <div className="row justify-content-xxl-between">
              <div className="col-12 col-xxl-8 px-0 px-sm-3 table-xxl">
                <TransactionHistory updateData={update} />
              </div>
              <div className="col-12 col-xxl-4 px-3 px-lg-0">
                <div className="Data order-0 order-lg-1 pb-1 px-lg-3 mb-0 mt-4 mt-lg-0">
                  <div className="row align-items-xxl-center justify-content-center">
                    <div className="col-xxl-12 col-md-9 mt-lg-3 mt-xxl-0 pr-lg-0 px-xxl-2">
                      <MyEarnings updatePage={handleUpdate} />
                    </div>
                    <div className="col-xxl-12 col-md-3 pl-md-0 px-lg-3 h-100">
                      <div className="row px-3 px-sm-0 mt-lg-3">
                        <div className="col-12 order-2 order-lg-1 col-sm-12 col-xxl-6 mt-3 mt-xxl-0 mb-xxl-2 pl-0  pl-sm-3 pr-0 pr-sm-3 pr-xl-3 px-xxl-2">
                          <Board>
                            <div className="p-3 py-5 py-xxl-4">
                              <div className="BoardImg mb-4">
                                <img src={watch} alt={language.wallet.img_1} />
                              </div>
                              <p className="text-center Title">
                                {language.wallet.p_1_1}
                                <br />
                                {language.wallet.p_1_2}
                              </p>
                              <p className="Value mb-lg-1 mb-xl-2 h5">
                                {availableSoon}
                              </p>
                            </div>
                          </Board>
                        </div>
                        <div className="col-12 col-sm-12 col-xxl-6 mt-3 mt-md-0 pl-0 pl-sm-3 pr-0 pr-sm-3 pr-xl-3 px-xxl-2">
                          <Board>
                            <div className="p-3 py-5 py-xxl-4">
                              <div className="BoardImg mb-4">
                                <img
                                  src={calendar}
                                  alt={language.wallet.img_2}
                                />
                              </div>
                              <p className="text-center Title">
                                {language.wallet.p_2_1}
                                <br />
                                {language.wallet.p_2_2}
                              </p>
                              <p className="Value h5 mb-2 mb-lg-0 mb-xl-3 mb-xxl-2">
                                $0.00
                              </p>
                            </div>
                          </Board>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Wallet;
