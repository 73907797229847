import styled from 'styled-components';

export const Container = styled.div`
  .bg-gray {
    background-color: #202020;
    border-radius: 20px;
    padding: 0 15px;
  }

  .scroll {
    ::-webkit-scrollbar {
      border-radius: 4px !important;
      height: 8px !important;
    }
  }
`;

export const Welcome = styled.div`
  h1 {
    color: #e4e4e4;
  }
  p {
    color: #969696;
  }
`;

export const Values = styled.div`
  background: #242526;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;

  p {
    color: #606060;
    font-weight: 400;
    span {
      color: #f7f7f7;
      font-weight: 600;
    }
  }

  hr {
    background: #e0e0e0;
  }

  .dark-gray {
    color: #606060;
  }

  @media screen and (min-width: 768px) {
    .border-md-right {
      border-right: solid 1px #e0e0e0;
    }
  }
`;

export const Table = styled.div`
  min-width: 1040px;
  margin-bottom: 10px;

  a:hover {
    text-decoration: none;
    background-color: #18191a;
    border-radius: 15px;
  }

  .line {
    > div {
      width: 250px !important;
      overflow-wrap: break-word;
    }

    > div:nth-child(2),
    > div:nth-child(3) {
      width: 180px !important;
    }

    > div:nth-child(4) {
      width: 300px !important;
    }
  }

  img.avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  div {
    span {
      color: #6e6e6e;
      font-size: 18px;
    }
  }

  @media screen and (max-width: 575px) {
    div {
      span {
        color: #6e6e6e;
      }
    }
  }
`;
