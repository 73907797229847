import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import CheckLogin from '~/pages/CheckLogin';
import SignIn from '~/pages/SignIn';
import Home from '~/pages/Home';
import CommissionHistory from '~/pages/CommissionHistory';
import Trainings from '~/pages/Trainings/List';
import Training from '~/pages/Training';
import MaterialPromocional from '~/pages/MaterialPromocional';
import Crowdfunding from '~/pages/Crowdfunding';
import Referrals from '~/pages/Referrals';
import TeamView from '~/pages/Referrals/TeamView';
import Wallet from '~/pages/Wallet';
import UpdateWarning from '~/pages/UpdateWarning';
import Affiliates from '~/pages/Affiliates';
import CrowdfundingList from '~/pages/Affiliates/Crowdfunding/List';
import PromotionalMaterial from '~/pages/Affiliates/PromotionalMaterial';
import TrainingList from '~/pages/Affiliates/Training/List';

const Es: React.FC = () => {
  return (
    <Switch>
      {/* <Route path={`${process.env.PUBLIC_URL}/`} component={UpdateWarning} /> */}
      <Route path={`${process.env.PUBLIC_URL}/es/`} exact component={SignIn} />
      <Route
        path={`${process.env.PUBLIC_URL}/es/dashboard`}
        component={Home}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/commission-history`}
        exact
        component={CommissionHistory}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/trainings`}
        exact
        component={Trainings}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/trainings/category/:slug`}
        exact
        component={Trainings}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/trainings/:slug`}
        exact
        component={Training}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/promotional-material`}
        exact
        component={MaterialPromocional}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/crowdfunding`}
        exact
        component={Crowdfunding}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/my-referrals`}
        exact
        component={Referrals}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/my-referrals/team-view/:slug`}
        exact
        component={TeamView}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/wallet/:slug`}
        exact
        component={Wallet}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/check-login/:token`}
        exact
        component={CheckLogin}
      />
    </Switch>
  );
};

export default Es;
