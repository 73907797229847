import styled from 'styled-components';

interface IFlagProps {
  show: boolean;
}

interface IThumbnailProps {
  src: string;
}

export const Container = styled.div``;

export const Welcome = styled.div`
  h1 {
    color: #e4e4e4;
  }
  p {
    color: #858585;
  }

  .search {
    padding: 1px;
    border-radius: 100px;
    border: 1px solid #4a4b4b;
    background-color: #202020;

    input {
      margin: 5px 0;
      padding: 0 10px;
      border: none;
      border-radius: 100px;
      background-color: #202020;
    }
  }

  .border-golden {
    :hover {
      color: #e4e4e4;
      text-decoration: none;
    }
  }

  @media screen and (max-width: 1399px) {
    p {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 991px) {
    p {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 400px) {
    h1 {
      font-size: 24px !important;
    }
  }
`;

export const Search = styled.div`
  padding: 1px;
  border-radius: 100px;
  border: 1px solid #4a4b4b;
  background-color: #202020;

  input {
    margin: 5px 0;
    padding: 0 10px;
    border: none;
    border-radius: 100px;
    background-color: #202020;
  }
`;

export const AddButton = styled.button`
  border-radius: 20px;
  overflow: hidden;
  background-color: #202020;
  min-height: 526px;

  .tinytext {
    color: #828282;
  }

  img {
    border-radius: 20px;
  }
`;

export const Card = styled.div`
  border-radius: 20px;
  overflow: hidden;
  background-color: #202020;
  min-height: 526px;

  .description-h {
    height: 120px;
    overflow: auto;
  }

  .img-profile {
    width: 43px;
    height: 43px;
    border-radius: 50%;
  }

  textarea {
    height: 29px;
    max-height: 180px;
    resize: none;
    overflow: hidden;
    cursor: pointer;
    background-color: transparent;
    border-radius: 5px;

    :focus {
      outline: none;
      cursor: unset;
      background-color: #18191a;
      box-shadow: none;
    }

    ::placeholder {
      color: #828282;
    }
  }

  .username {
    color: #969696;
  }

  .tinytext {
    color: #828282;
  }

  small {
    color: #cbccce;
  }

  button.btn-top {
    padding: 1px;
    width: 40%;
    text-align: center;

    :hover {
      text-decoration: none;
    }
  }

  .btn-share {
    background-color: #2c2c2c !important;
  }

  button:hover {
    filter: brightness(1.3);
    transition-duration: 0.2s;
    img {
      filter: brightness(1.5);
    }
  }

  button.border-delete {
    color: #ff4d77;
  }

  img {
    border-radius: 20px;
  }

  .border-golden {
    padding: 1px !important;
  }

  @media screen and (max-width: 767px) {
    .description-h {
      height: auto;
    }
  }
`;

export const Thumbnail = styled.div<IThumbnailProps>`
  height: 222px;
  background-color: #18191a;
  border-radius: 10px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 767px) {
    height: 200px;
  }

  @media screen and (max-width: 575px) {
    height: 160px;
  }
`;

export const LinkCopy = styled.div`
  background: #343434;

  textarea {
    height: auto;
    color: #e6e7e7;
  }
`;

export const Flag = styled.div<IFlagProps>`
  right: -15px;
  top: -20px;
  border-radius: 10px;
  background: #18191a;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};

  ::after {
    content: '';
    width: 5px;
    height: 5px;
    background: #18191a;
    position: absolute;
    bottom: -2px;
    z-index: 10000;
    left: 50%;
    transform: rotate(45deg);
  }
`;
