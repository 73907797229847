import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Modal = styled(ModalComponent)`
  .input-group-prepend > .input-group-text:not(:last-child),
  .form-control:not(:last-child),
  .input-group > .form-control:not(:last-child) {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border-color: #c53030 !important;
  }

  .input-group > .custom-select:not(:first-child),
  .input-group > .form-control:not(:first-child) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .text-danger {
    position: absolute;
    margin-top: 64px;
    width: 100%;
    text-align: center;
  }

  .total-withdraw {
    color: #e4e4e4 !important;
  }
  .color {
    color: #767677;
  }
  .btn-cancel {
    background-color: #242526;
    color: #fff;
    font-size: 18px;
    border-radius: 15px;
    :hover {
      filter: brightness(1.3);
    }
  }

  .btn-confirm {
    background-color: #606060;
    color: #fff;
    font-size: 18px;
    border-radius: 15px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    :hover {
      filter: brightness(1.3);
    }
  }
  .input-group {
    .form-control {
      padding: 5px 10px 5px 60px !important;
    }
    input {
      height: 41px;
      width: 100%;
      color: #828282;
    }
  }
  .modal-content {
    background: #1f1f1f !important;
    box-shadow: 19px 49px 112px rgba(0, 0, 0, 0.4),
      inset 2px 2px 2px rgba(107, 107, 107, 0.5),
      inset -1px -1px 2px rgba(43, 43, 43, 0.5);
    border-radius: 30px;
  }

  .container .close {
    color: white;
  }

  .container .h2 {
    color: #dadada;
  }

  .container .description {
    color: #9b9b9b;
    font-size: 18px;
  }

  .container .label {
    color: #cbccce;
    font-size: 14px;
  }

  .form-control {
    background-color: #1f1f1f;
    border: 1.5px solid #454546;
  }

  input[type='text'] {
    background-color: #1f1f1f;

    color: #828282;
    outline: none;
    border-radius: 13px;
    height: 41px;
  }
  .form-control:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .input-group-text {
    background-color: #1f1f1f;
    color: #8d8d8d;
    border: none;
    position: absolute;
    top: 9px;
    left: 5px;
    z-index: 100;
    border-right: none !important;
    border-radius: 10px 0 0 10px;
  }

  hr {
    border: 1px solid #393c3d;
  }

  .container .value {
    color: #8d8d8d;
  }
  .container .data {
    color: #9b9b9b;
    font-size: 14px;
  }

  .container .pincode-input-container {
    .pincode-input-text {
      padding: 0 !important;
      margin: 0 2px;
      text-align: center;
      border: 1px solid;
      border-radius: 15px;
      background: transparent;
      width: 50px;
      height: 50px;
      border: 1.5px solid #454546 !important;
      color: #828282 !important;
    }
    .pincode-input-text:focus {
      outline: none;
      box-shadow: none;
      filter: brightness(2.5);
    }
  }

  @media screen and (max-width: 576px) {
    .btn-submit {
      font-size: 15px;
    }

    .container .description {
      color: #9b9b9b;
      font-size: 14px;
    }
    .container .h4 {
      font-size: 18px;
    }
    input[type='text'] {
      font-size: 14px;
    }
    span {
      font-size: 14px;
    }
    input[type='password'] {
      font-size: 14px;
    }

    .container .pincode-input-container {
      .pincode-input-text {
        margin-top: 0px;
        width: 10.7vw !important;
        height: 9.3vw !important;
      }
      .pincode-input-text:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  @media screen and (max-width: 400px) {
    .container .pincode-input-container {
      .pincode-input-text {
        width: 9.1vw !important;
        height: 11vw !important;
        border-radius: 8px !important;
      }
    }
  }

  @media screen and (min-width: 576px) {
    .container .pincode-input-container {
      .pincode-input-text {
        margin-top: 0px;
        width: 61px !important;
        height: 50px !important;
      }
      .pincode-input-text:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  @media screen and (min-width: 992px) {
    .container .data {
      font-size: 18px;
    }
    .pincode-input-container {
      display: flex;
    }
    .container .pincode-input-container {
      .pincode-input-text {
        margin-top: 0px;
        width: 70px !important;
        height: 60px !important;
      }
      .pincode-input-text:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
  @media screen and (min-width: 1200px) {
  }
`;
