import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import CheckLogin from '~/pages/CheckLogin';
import SignIn from '~/pages/SignIn';
import Home from '~/pages/Home';
import CommissionHistory from '~/pages/CommissionHistory';
import Trainings from '~/pages/Trainings/List';
import Training from '~/pages/Training';
import MaterialPromocional from '~/pages/MaterialPromocional';
import Crowdfunding from '~/pages/Crowdfunding';
import Referrals from '~/pages/Referrals';
import TeamView from '~/pages/Referrals/TeamView';
import Wallet from '~/pages/Wallet';
import UpdateWarning from '~/pages/UpdateWarning';
import Affiliates from '~/pages/Affiliates';
import CrowdfundingList from '~/pages/Affiliates/Crowdfunding/List';
import PromotionalMaterial from '~/pages/Affiliates/PromotionalMaterial';
import TrainingList from '~/pages/Affiliates/Training/List';

const routes: React.FC = () => {
  return (
    <Switch>
      {/* <Route path={`${process.env.PUBLIC_URL}/`} component={UpdateWarning} /> */}
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={SignIn} />
      <Route
        path={`${process.env.PUBLIC_URL}/dashboard`}
        component={Home}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/commission-history`}
        exact
        component={CommissionHistory}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/trainings`}
        exact
        component={Trainings}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/trainings/category/:slug`}
        exact
        component={Trainings}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/trainings/:slug`}
        exact
        component={Training}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/promotional-material`}
        exact
        component={MaterialPromocional}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/crowdfunding`}
        exact
        component={Crowdfunding}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/my-referrals`}
        exact
        component={Referrals}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/my-referrals/team-view/:slug`}
        exact
        component={TeamView}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/wallet/:slug`}
        exact
        component={Wallet}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/check-login/:token`}
        exact
        component={CheckLogin}
      />
    </Switch>
  );
};

export default routes;
