import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from '~/hooks/Auth';
import api from '~/services/api';

import Info from '~/components/Info';

import Header from '~/components/Header';

import { Wrapper, HeaderSpacer, Separator, FooterSpacer } from './styles';

const DefaultLayout: React.FC = ({ children }) => {
  const location = useLocation();
  const { user, signOut } = useAuth();

  const noFullSize = useMemo(
    () =>
      !location.pathname.match(/\/commission-history.*/) &&
      !location.pathname.match(/\/trainings.*/) &&
      !location.pathname.match(/\/promotional-material.*/) &&
      !location.pathname.match(/\/crowdfunding.*/) &&
      !location.pathname.match(/\/affiliates.*/) &&
      !location.pathname.match(/\/training.*/),
    [location.pathname]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    api.get(`/`).catch(() => {
      signOut();
    });
  }, [location, signOut, user]);

  return (
    <Wrapper>
      <HeaderSpacer>
        <Header />
      </HeaderSpacer>
      <div className="d-flex flex-wrap flex-xl-nowrap separator-container">
        <Separator
          className="order-0 order-lg-1 order-xl-0"
          noPadding={!!location.pathname.match(/\/trainings\/.*/)}
          fullSize={!noFullSize}
        >
          {children}
        </Separator>
        {noFullSize && (
          <FooterSpacer className="order-1 order-lg-0 order-xl-1 px-0 px-lg-2 px-xl-0">
            <Info />
          </FooterSpacer>
        )}
      </div>
    </Wrapper>
  );
};

export default DefaultLayout;
